<template>
	<b-card id="riskByLocation">
		<b-card-title>
			<div class="title" :title="title">
				{{ title }}
				<font-awesome-icon id="riskByLocationTooltip" :icon="['fas', 'info-circle']" class="mr-1"/>
				<b-tooltip class="mt-2 mr-2" triggers="hover" right target="riskByLocationTooltip">
					<span v-html="$t('Dashboard.Tooltips.RiskByLocationInfo')"></span>
				</b-tooltip>
			</div>
			<b-col class="filter-container">
				<span v-html="breadCrumb" @click="removeFilter"></span>
			</b-col>
		</b-card-title>
		<div class="wrapper-canvas">
			<cg-horizontal-bar-chart v-if="showChart" :chart-data="chartData" :options="options" ref="chart" 
				:style="{'min-height' : minHeight }"></cg-horizontal-bar-chart>
		</div>
	</b-card>
</template>

<script> 
import HorizontalBarChart from "@/components/charts/wrappers/HorizontalBarChart";
import CompanyService from "@/services/company.service";

import colors from "@/components/charts/utils/colors";
import { HBarChartsOptions } from "@/components/charts/utils/options";


export default {
	components: {
		"cg-horizontal-bar-chart": HorizontalBarChart
	},
	data() {
		return {
			title: this.$t('Dashboard.Charts.RiskByLocation'),
			myself: "site",
			selectedIndex: null,
			breadCrumb: "",
			chartData: {},
			options: {},
			inProgress: false,
			showChart: false,
			myselfClicked: false
		};
	},
	props: {
		cds: Object,
	},
	computed: {
		minHeight() {
			// Min height is 400px - gradually increase canvas height with dataset growth
			if(!this.chartData.datasets || !this.chartData.datasets[0]) return '400px';
			// Namely add 200px for 15 elements, 400px for 38 elements and so on, plus 300px for each 60 elements
			return '' + ((Math.ceil(this.chartData.datasets[0].data.length / 10) * 100) + (300 * Math.ceil(this.chartData.datasets[0].data.length / 60))) + 'px';
		}
	},
	mounted() {
		this.setOptions();
		this.init();

		this.cds.getBus().$on("filter_changed", () => {
			this.breadCrumb = this.cds.getInteractiveBreadcrumb();
			this.refresh();
		});
	},
	methods: {
		async init() {
			let data = await this.getData();
			if (data && Object.keys(data).length > 0) {
				this.createChart(data);
			}
		},
		async refresh() {
			let data = !this.myselfClicked ? await this.getData() : null;

			if (this.cds.hasOtherValidFilters(this.myself)) {
				if (data) this.addDataset(data);
			} else {
				this.removeDataset();
			}
			this.toggleActive();
		},
		// Retrieve the clicker rate
		async getData() {
			this.inProgress = true;

			const filters = this.cds.getFiltersFor(this.myself);
			const queryParams = filters ? filters : {};
			queryParams.ctx = this.cds.ctx;

			let result = this.cds.companyId ? await CompanyService.getRiskByLocation(this.cds.companyId, queryParams) : {};
			this.inProgress = false;
			this.showChart = result.data ? true : false;
			return result.data;
		},
		createChart(data) {
			this.chartData = {
				labels: [],
				datasets: []
			};
			this.addDataset(data);
			this.addClickHandler();
			this.createLegend();
		},
		addDataset(data) {
			const first = this.chartData.datasets.length == 0;

			if (first) {
				let dataset = {
					label: [this.$t("Dashboard.Charts.Site")],
					data: [],
					active: [],
					backgroundColor: [],
					borderColor: [],
					borderWidth: 1,
					maxBarThickness: 100
				};
				data.reverse().forEach((summary) => {
					let clickRate = (100 * (summary.n_clicked / summary.n_sent)).toFixed(1);
					dataset.backgroundColor.push(colors.alpha.blue);
					dataset.borderColor.push(colors.plain.blue);

					this.chartData.labels.push(summary.site);
					dataset.active.push(false);
					dataset.data.push(clickRate);
				});

				this.chartData.datasets[0] = dataset;
			}
			else {
				let dataset = {
					label: this.cds.getBreadcrumbFor(this.myself),
					data: this.chartData.datasets[0].data.map(() => null),
					backgroundColor: this.chartData.datasets[0].backgroundColor.map(() => colors.alpha.green),
					borderColor: this.chartData.datasets[0].borderColor.map(() => colors.plain.green),
					borderWidth: 1,
					maxBarThickness: 100
				};

				data.forEach((d) => {
					dataset.data[this.chartData.labels.indexOf(d.site)] = (100 * (d.n_clicked / d.n_sent)).toFixed(1);
				});

				this.chartData.datasets[1] = dataset;
			}

		},
		removeDataset() {
			if (this.chartData.datasets[1]) {
				this.chartData.datasets.pop();
			}
			this.toggleActive();
		},
		// Register the click event on the chart
		addClickHandler() {
			var canvas = this.$refs.chart.$data._chart.canvas;
			canvas.onclick = this.handleClick;
			canvas.onmousemove = this.handleHover;
		},
		createLegend() {
			this.$refs.chart.addPlugin({
				id: 'custom-legend',
				beforeDraw: function (chart) {
					var legendItems = chart.legend.legendItems;
					legendItems.forEach(function (item, index) {
						item.fillStyle = colors.palette.dark[index % colors.palette.dark.length];
						item.strokeStyle = colors.palette.dark[index % colors.palette.dark.length];
					});
				}
			});
		},
		// Set the options for the chart
		setOptions() {
			this.options = Object.assign({
				scales: {
					yAxes: [
						{
							ticks: {
								autoSkip: false, 
								source: 'data'
							}
						}
					]
				},
				legend: {
					labels: {
						fontColor: colors.plain.primary,
						fontSize: 16,
						padding: 20,
					},
					onClick: this.handleLegendClick,
				}
			}, HBarChartsOptions);
		},
		// Highligth the selected slice of the pie chart
		highlightDataset(elementIndex, active) {
			const bgColor = active ? colors.alpha.blue : colors.alpha.lightBlue;
			const brColor = active ? colors.plain.blue : colors.alpha.lightBlue;

			this.chartData.datasets[0].backgroundColor[elementIndex] = bgColor;
			this.chartData.datasets[0].borderColor[elementIndex] = brColor;
		},
		// Handle the click event
		handleClick(evt) {
			const chart = this.$refs.chart.$data._chart;
			let activeElement = chart.getElementAtEvent(evt);

			if (activeElement.length > 0) {
				activeElement = activeElement[0];

				if (0 == activeElement._datasetIndex) {
					this.myselfClicked = true;
					const reset = this.selectedIndex == activeElement._index;
					this.selectedIndex = reset ? null : activeElement._index;

					const label = this.chartData.labels[activeElement._index];
					const filter = label ? label : this.$t("Dashboard.Charts.Site") + "_" + this.cds.NotAssignedLabel;

					this.cds.setFilter(this.myself, reset ? null : filter);
					this.cds.emitFilterChanges();
					this.cds.setQueryParam(this.myself, reset ? null : filter);
					this.cds.reload();
				}
			}
		},
		handleHover(evt) {
			if (this.chartData) {
				var chart = this.$refs.chart.$data._chart;
				var activeElement = chart.getElementAtEvent(evt);
				if (activeElement.length > 0) {
					activeElement = activeElement[0];
					if (activeElement._datasetIndex == 0) {
						evt.target.style.cursor = 'pointer';
						this.highlightDataset(activeElement._index, true);
					}
					this.$refs.chart.$data._chart.update();
				} else {
					evt.target.style.cursor = 'default';
					this.toggleActive();
				}
			}
		},
		// Handle the click event on the legend
		handleLegendClick(e, legendItem) {
			let index = legendItem.datasetIndex;
			let chart = this.$refs.chart.$data._chart;
			let alreadyHidden = !chart.getDatasetMeta(index).hidden ? false : chart.getDatasetMeta(index).hidden;

			chart.data.datasets.forEach(function (dataset, datasetindex) {
				let meta = chart.getDatasetMeta(datasetindex);

				if (chart.data.datasets.length === 1) {
					meta.hidden = !meta.hidden;
				} else {
					meta.hidden = !alreadyHidden && meta.hidden ? !meta.hidden && !alreadyHidden : datasetindex !== index;
				}
			});

			this.$refs.chart.$data._chart.update();
		},
		// Toggle active status
		toggleActive() {
			const elementIndex = this.selectedIndex;
			const myselfActive = this.cds.filter[this.myself];
			const othersActive = this.cds.hasOtherValidFilters(this.myself);
			this.myselfClicked = false;

			this.chartData.datasets[0].data.forEach((value, index) => {
				this.chartData.datasets[0].active[index] = (index == elementIndex) && myselfActive;
				this.highlightDataset(index, myselfActive ? this.chartData.datasets[0].active[index] : !othersActive);
			});
			this.$refs.chart.$data._chart.update();
		},
		removeFilter(event) {
			if (event.target.classList.contains("close")) {
				this.cds.removeFilter(event.target.getAttribute("filter"));
				this.cds.emitFilterChanges();
			}
		}
	}
}
</script>

<style lang="less">
#riskByLocation {
	min-height: 484px;
	.wrapper-canvas {
		max-height: 400px; 
		overflow-y: auto;
	}
}
</style>