import axios from '../plugins/axios.js';
import { RiskGroup, Campaign, NotAssignedLabel, TemplateType } from '../../../server/common/constants';
import { truncateStr } from "@/components/utils";

const CdsXform = Object.freeze({
    AggregateCampaigns: 1,
    IsolateCampaign: 2,
    IsolateRiskGroup: 3,
    SummarizeCampaign: 4
});

const Filter = Object.freeze({
    campaign: "campaign",
    risk: "risk",
    riskGroup: "risk_group",
    site: "site",
    org_1: "org_1",
    org_2: "org_2",
    org_3: "org_3",
    org_4: "org_4"
});

var maximumCampaigns = null;
var filter = {};
var filterName = {};
var defaultFilter = [];
var selectedCampaignIndex = null;
var selectedRiskIndex = null;
var selectedRiskGroup = null;
var legendItemActive = false;
var switched = true;
var reversed = false;
var isReportAvailable = false;

function createStats(sent, open, clicked, error, reported, targets) {
    return { sent: sent || 0, open: open || 0, clicked: clicked || 0, error: error || 0, reported: reported || 0, targets: targets || 0 };
}

function sumStats(list) {
    let a = createStats();

    list.forEach(s => {
        a.sent += s.sent || 0;
        a.open += s.open || 0;
        a.clicked += s.clicked || 0;
        a.error += s.error || 0;
        a.reported += s.reported || 0;
        a.targets += s.targets || 0;
    });

    return a;
}

function initCampaignStats(campaign) {
    campaign.stats = {};
    campaign.sms_stats = {};
    campaign.active_stats = {};

    for (let r in RiskGroup) {
        campaign.stats[RiskGroup[r]] = createStats();
        campaign.sms_stats[RiskGroup[r]] = createStats();
    }
}

// Data transform: aggregates a list of campaigns
function createAggregateCampaignsTransform(ctx) {
    (ctx);

    return function (campaigns) {
        let result = {
            campaign_id: '',
            name: 'C-Aggregate',
            n_campaigns: 0
        };

        initCampaignStats(result);

        campaigns.reduce((a, c) => {
            a.n_campaigns += 1;

            for (let r in RiskGroup) {
                const g = RiskGroup[r];

                a.stats[g] = sumStats([a.stats[g], c.stats[g]]);
                a.sms_stats[g] = sumStats([a.sms_stats[g], c.sms_stats[g]]);
            }

            return a;
        }, result);

        return [result];
    };
}

// Data transform: removes all campaigns except for the specified
function createIsolateCampaignTransform(ctx, param) {
    return function (campaigns) {
        return campaigns.filter((c) => c.campaign_id === param.id);
    };
}

function createIsolateRiskGroupTransform(ctx, group) {
    return function (campaigns) {
        campaigns.forEach(c => {
            for (let r in RiskGroup) {
                const g = RiskGroup[r];
                if (g !== group) {
                    c.stats[g] = 0;
                }
            }
        });

        return campaigns;
    };
}

function summarizeCampaignData(campaign) {
    let stats = [];
    let sms_stats = [];

    for (let r in RiskGroup) {
        stats.push(campaign.stats[RiskGroup[r]]);
        sms_stats.push(campaign.sms_stats[RiskGroup[r]]);
    }

    return Object.assign({ total: sumStats(stats), sms_total: sumStats(sms_stats) }, campaign);
}

function createSummarizeCampaignTransform(ctx) {
    (ctx);
    return function (campaigns) {
        return campaigns.map(c => summarizeCampaignData(c));
    };
}

function createCampaignDashboardDataSourceTransform(ctx, name, priority, param) {
    let handler;

    switch (name) {
        case CdsXform.AggregateCampaigns:
            handler = createAggregateCampaignsTransform(ctx);
            break;
        case CdsXform.IsolateCampaign:
            handler = createIsolateCampaignTransform(ctx, param);
            break;
        case CdsXform.IsolateRiskGroup:
            handler = createIsolateRiskGroupTransform(ctx, param);
            break;
        case CdsXform.SummarizeCampaign:
            handler = createSummarizeCampaignTransform(ctx);
            break;
        default:
            return null;
    }

    return {
        name: name,
        handler: handler,
        param: param,
        priority: priority
    };
}

function sumDataByKey(...objs) {
    return objs.reduce((a, b) => {
        for (let k in b) {
            if (b.hasOwnProperty(k)) {
                a[k] = (a[k] || 0) + b[k];
            }
        }
        return a;
    }, {});
}

function createCampaignDashboardDataSource(ctx, company_id, account) {
    const context = "dashboard";
    let campaigns = null;
    let transforms = [];
    let query_params = {};
    let self = {};
    const companyId = company_id;

    if(account.filtered_orgs && account.filtered_orgs[companyId]) {
		setDefaultFilters(account.filtered_orgs[companyId]);
	}

    function setQueryParam(param, value) {

        if (value) {
            query_params[param] = value;
        } else {
            // Prevent org charts from deleting account default filters already applied
            if(defaultFilter.indexOf(param) == -1) {
                delete query_params[param];
            }
        }

        ctx.bus.$emit('cds_query_param_change');
    }

    async function fetchCampaignData(campaign) {
        ctx.log.debug('Fetch data for campaign', campaign.name, '#', campaign.status, '@', company_id);

        // Reset the campaign statistics
        initCampaignStats(campaign);

        // Fetch data from the timeline
        let params = [];

        for (let p in query_params) {
            params.push(params.length ? '&' : '?');
            params.push(p);
            params.push('=');
            params.push(query_params[p]);
        }

        //const stats = await $.getJSON('/api/campaign_stats/'+campaign.campaign_id+params.join('')).promise();
        const active_stats = companyId ? await axios.get('campaign/' + campaign.campaign_id + '/active/' + params.join('')) : {};

        active_stats.data.forEach(e => {
            campaign.active_stats[e.target_risk || 0] = e.count;
            
            if(e.remediations > 0) {
                campaign.remediated = true;
            }
        });

        const data_x = companyId ? await axios.get('campaign/' + campaign.campaign_id + '/stats/' + params.join('')) : {};
        const stats = data_x.data;

        // Update the campaign statistics
        stats.forEach(g => {
            if(g.type == TemplateType.SMS) {
                campaign.sms_stats[g.target_risk || 0] = sumDataByKey(campaign.sms_stats[g.target_risk || 0], createStats(g.sent, g.open, g.clicked, g.error, g.reported, g.targets));
            }

            campaign.stats[g.target_risk || 0] = sumDataByKey(campaign.stats[g.target_risk || 0], createStats(g.sent, g.open, g.clicked, g.error, g.reported, g.targets));
        });

        ctx.log.info(campaign);
        ctx.log.info();
    }

    async function getReportEnabled() {
        try {
            const company = companyId ? await axios.get(`company/${companyId}`) : {};
            return company? !!company.data.reporting_enabled : false
        } catch (ex) {
            ctx.log.error(ex);
        }
        
        return false;
    }

    async function getHideRiskEvolution() {
        try {
            const company = companyId ? await axios.get(`company/${companyId}`) : {};
            return company ? !!company.data.hide_evolution : false;
        } catch (ex) {
            ctx.log.error(ex);
        }
        
        return false;
    }

    async function refresh(reload) {
        ctx.bus.$emit('cds_refresh_start');

        // Get data
        try {
            if (!campaigns || reload) {
                // Fetch initial data
                ctx.log.debug('Fetching initial data');

                //campaigns = await $.getJSON('/api/campaigns').promise();
                const data_x = companyId ? await axios.get(`company/${companyId}/lastCampaigns`, { params: { ctx: context } }) : {};
                campaigns = data_x.data || [];

                for (let i = 0; i < campaigns.length; i++) {
                    await fetchCampaignData(campaigns[i]);
                }
            }
            else {
                // Refresh data for campaigns that are not in a final state
                for (let i = 0; i < campaigns.length; i++) {
                    if (campaigns[i].status !== Campaign.Status.Archived) {
                        await fetchCampaignData(campaigns[i]);
                    }
                }
            }
        }
        catch (ex) {
            ctx.log.error(ex);
        }
        removeAllTransforms();
        ctx.bus.$emit('cds_refresh_end');
    }

    // Getters
    function getRawData() {
        return campaigns;
    }

    function getData() {
        // Apply transformations
        transforms.sort((x1, x2) => x1.priority - x2.priority);

        let data = JSON.parse(JSON.stringify(campaigns));

        transforms.forEach(t => {
            ctx.log.info('Applying transform', t.name);

            data = t.handler(data);
        });

        ctx.log.info('Xformed:', data);

        return data;
    }

    // Transformers management
    function createTransform(name, priority, param) {
        return createCampaignDashboardDataSourceTransform(ctx, name, priority, param);
    }

    let debounceTransformChangedTimer;

    function transformChanged() {
        clearTimeout(debounceTransformChangedTimer);
        debounceTransformChangedTimer = setTimeout(() => {
            ctx.bus.$emit('cds_transform_change');
        }, 50);
    }

    function addTransform(name, priority, param) {
        if (typeof name == 'function') {
            removeTransform(name.name);

            transforms.push({
                name: name.name,
                handler: name,
                param: param,
                priority: priority
            });
        }
        else {
            removeTransform(name);

            const xform = createTransform(name, priority, param);

            if (xform) {
                transforms.push(xform);
            }
        }

        transformChanged();
    }

    function hasTransform(name) {
        for (let i = 0; i < transforms.length; i++) {
            if (transforms[i].name === name) {
                return true;
            }
        }
        return false;
    }

    function removeTransform(name) {
        if (typeof name == 'function') {
            name = name.name;
        }

        for (let i = 0; i < transforms.length; i++) {
            if (transforms[i].name === name) {
                transforms.splice(i, 1);
                transformChanged();
                break;
            }
        }
    }

    function removeAllTransforms() {
        if (transforms.length > 0) {
            transforms = [];
            transformChanged();
        }
    }

    function applyTransform(data, name, param) {
        const xform = createTransform(name, 0, param);

        if (xform) {
            data = xform.handler(data);
        }
        else {
            ctx.log.warn('Cannot create transform', name);
        }

        return data;
    }

    function setFilter(id, value, displayName) {
        // Prevent org charts from deleting account default filters already applied
        if(defaultFilter.indexOf(id) != -1) return;

        if (filter[id] === value) {
            if (filter[id] == null) {
                return;
            }
            value = null;
        }
        filter[id] = value;
        filterName[id] = displayName || value;

        return true;
    }

    function setDefaultFilters(filteredOrg) {
        Object.keys(filteredOrg).forEach((key) => {
            setFilter(key, filteredOrg[key]);
            setQueryParam(key, filteredOrg[key]);
            // Avoid adding twice account default filters
            if(defaultFilter.indexOf(key) == -1) { 
                defaultFilter.push(key);
            }
        });
    }

    function emitFilterChanges() {
        ctx.bus.$emit("filter_changed");
    }

    function removeAllFilters() {
        for (const f in filter) {
            if (filter.hasOwnProperty(f) && defaultFilter.indexOf(f) == -1) {
                filter[f] = null;
            }
        }
    }

    function getOtherFilters(widget_name) {
        let result = [];
        for (let f in filter) {
            if (f != widget_name && (filter[f] || Number.isInteger(filter[f]))) {
                result.push({ filter: f, value: filter[f], name: filterName[f] });
            }
        }
        return result;
    }

    function getFiltersFor(widget_name) {
        let result = {};
        for (let f in filter) {
            if ((f != widget_name || defaultFilter.indexOf(widget_name) != -1) && (filter[f] || Number.isInteger(filter[f]))) {
                result[f] = filter[f];
            }
        }
        return result;
    }

    function hasOtherFilters(widget_name) {
        return getOtherFilters(widget_name).length > 0;
    }

    function hasOtherValidFilters(widget_name) {
        let result = [];
        for (let f in filter) {
            if(defaultFilter.indexOf(f) == -1 && f != widget_name && (filter[f] || Number.isInteger(filter[f]))) {
                result.push({ filter: f, value: filter[f], name: filterName[f] });
            }
        }
        return result.length > 0;
    }

    function getBreadcrumbFor(widget_name) {
        return getOtherFilters(widget_name).filter((f) => defaultFilter.indexOf(f.filter) == -1).map((f) => {
            const fName = f.name.indexOf(NotAssignedLabel) === -1 ? f.name : f.name.split("_" + NotAssignedLabel)[0] + " (N.A.)";
            return truncateStr(fName, 20);
        }).join(" / ");
    }

    function getInteractiveBreadcrumb(widget_name) {
        return getOtherFilters(widget_name).map((f) => createBreadCrumb(f)).join("");
    }

    function getInteractiveBreadcrumbWithout(filters) {
        return filters.map((f) => createBreadCrumb(f)).join("");
    }

    function createBreadCrumb(filter) {
        // No breadcrumb is shown for account default filter
        if(defaultFilter.indexOf(filter.filter) != -1) {
            return;
        }
        const fName = filter.name.indexOf(NotAssignedLabel) === -1 ? filter.name : filter.name.split("_" + NotAssignedLabel)[0] + " (N.A.)";
        return `<div title="${fName}" role="alert" aria-live="polite" aria-atomic="true" class="alert alert-dismissible alert-info" :filter="null">
            <button type="button" aria-label="Close" class="close" filter="${filter.filter}">×</button>${truncateStr(fName, 20)}</div>`;
    }

    function removeFilter(filter_name) {
        switch (filter_name) {
            case Filter.campaign:
                this.selectedCampaignIndex = null;
                this.selectedRiskIndex = null;
                if (!this.legendItemActive) {
                    setFilter(Filter.risk, null);
                    removeTransform(CdsXform.IsolateRiskGroup);
                }
                setFilter(Filter.campaign, null);
                removeTransform(CdsXform.IsolateCampaign);
                break;

            case Filter.risk:
                this.selectedRiskIndex = null;
                setFilter(Filter.risk, null);
                removeTransform(CdsXform.IsolateRiskGroup);
                break;

            case Filter.riskGroup:
                this.selectedRiskIndex = null;
                setFilter(Filter.riskGroup, null);
                removeTransform(CdsXform.IsolateRiskGroup);
                break;

            case Filter.site:
                setFilter(Filter.site, null);
                setQueryParam(Filter.site, null);
                refresh(true);
                break;

            case Filter.org_1:
                setFilter(Filter.org_1, null);
                setQueryParam(Filter.org_1, null);
                refresh(true);
                break;
            
            case Filter.org_2:
                setFilter(Filter.org_2, null);
                setQueryParam(Filter.org_2, null);
                refresh(true);
                break;

            case Filter.org_3:
                setFilter(Filter.org_3, null);
                setQueryParam(Filter.org_3, null);
                refresh(true);
                break;
            
            case Filter.org_4:
                setFilter(Filter.org_4, null);
                setQueryParam(Filter.org_4, null);
                refresh(true);
                break;

            default:
                break;
        }
    }

    // Expose interface methods
    self.ctx = context;
    self.getReportEnabled = getReportEnabled;
    self.refresh = refresh;
    self.reload = () => refresh(true);

    self.getData = getData;
    self.getRawData = getRawData;

    self.createTransform = createTransform;
    self.addTransform = addTransform;
    self.removeTransform = removeTransform;
    self.removeAllTransforms = removeAllTransforms;
    self.applyTransform = applyTransform;
    self.hasTransform = hasTransform;
    self.getTransforms = () => transforms;

    self.setQueryParam = setQueryParam;

    self.getQueryParams = () => query_params;

    self.getBus = () => ctx.bus;

    self.bus = ctx.bus;

    self.CdsXform = CdsXform;

    self.maximumCampaigns = maximumCampaigns;
    self.filter = filter;
    self.filterName = filterName;
    self.selectedCampaignIndex = selectedCampaignIndex;
    self.selectedRiskIndex = selectedRiskIndex;
    self.selectedRiskGroup = selectedRiskGroup;
    self.legendItemActive = legendItemActive;
    self.switched = switched;
    self.reversed = reversed;

    self.companyId = company_id;
    self.setFilter = setFilter;
    self.emitFilterChanges = emitFilterChanges;
    self.getOtherFilters = getOtherFilters;
    self.getFiltersFor = getFiltersFor;
    self.hasOtherFilters = hasOtherFilters;
    self.hasOtherValidFilters = hasOtherValidFilters;
    self.getBreadcrumbFor = getBreadcrumbFor;
    self.getInteractiveBreadcrumb = getInteractiveBreadcrumb;
    self.getInteractiveBreadcrumbWithout = getInteractiveBreadcrumbWithout;
    self.removeFilter = removeFilter;
    self.removeAllFilters = removeAllFilters;
    self.Filter = Filter;
    self.NotAssignedLabel = NotAssignedLabel;
    self.RiskGroup = RiskGroup;
    self.isReportAvailable = isReportAvailable;
    self.getHideRiskEvolution = getHideRiskEvolution;

    return self;
}

// Example of custom data transformation
function extractClickersInfoTransform(campaigns) {
    campaigns.forEach(c => {
        c.clickers = {
            unknown: c.stats[RiskGroup.Unknown].clicked,
            serial: c.stats[RiskGroup.Serial].clicked,
            frequent: c.stats[RiskGroup.Frequent].clicked,
            rare: c.stats[RiskGroup.Rare].clicked,
            defender: c.stats[RiskGroup.Defender].reported
        };
        c.clickers.total = c.clickers.unknown + c.clickers.serial + c.clickers.frequent + c.clickers.rare + c.clickers.defender;
    });

    return campaigns;
}

if (window.vattelappesca) {
    extractClickersInfoTransform();
    createSummarizeCampaignTransform();
}

export default createCampaignDashboardDataSource;