<template>
	<div>
		<div class="overlay-text">
			<div class="padding-200"></div>
			<span class="outline">
				{{ $t('Dashboard.Charts.HideRiskEvolution') }}
			</span>
		</div>
		<div class="overlay ccs"></div>
		<b-card v-if="showChart">
			<b-card-title>
				<div class="title" :title="title">{{ title }}</div>
			</b-card-title>
			<cg-bar-chart :chart-data="chartData" :options="options" :height="450" ref="chart"></cg-bar-chart>
		</b-card>
	</div>
</template>

<script> 
import BarChart from "@/components/charts/wrappers/BarChart";
import colors from "@/components/charts/utils/colors";

export default {
	components: {
		"cg-bar-chart": BarChart
	},
	data() {
		return {
			title: this.$t("Dashboard.Charts.RiskEvolution"),
			myself: "stacked100",
			showChart: false,
			chartData: {},
			chartLabels: [],
			options: {},
			riskGroup: { Unknown: 0, Serial: 1, Frequent: 2, Rare: 3, Defender: 4 }
		};
	},
	async mounted() {
		this.setOptions();
		this.chartLabels.push(this.$t('Dashboard.Charts.Strong'), this.$t('Dashboard.Charts.Intermediate'), this.$t('Dashboard.Charts.Weak'));
		this.createChart();
	},
	methods: {
		createChart() {
			let mockData = [{
				serial_click: 0.8,
				frequent_click: 18.0,
				rare_click: 81.2,
				name: "C-12"
			}, {
				serial_click: 1.5,
				frequent_click: 25.0,
				rare_click: 73.5,
				name: "C-11"
			},{
				serial_click: 3.2,
				frequent_click: 32.2,
				rare_click: 64.6,
				name: "C-10"
			}, {
				serial_click: 4.3,
				frequent_click: 40.8,
				rare_click: 54.9,
				name: "C-09"
			},{
				serial_click: 7.9,
				frequent_click: 47.4,
				rare_click: 44.7,
				name: "C-08"
			}, {
				serial_click: 13.0,
				frequent_click: 50.9,
				rare_click: 36.1,
				name: "C-07"
			},{
				serial_click: 21.7,
				frequent_click: 50.7,
				rare_click: 27.6,
				name: "C-06"
			}, {
				serial_click: 27.4,
				frequent_click: 52.8,
				rare_click: 19.8,
				name: "C-05"
			},{
				serial_click: 48.7,
				frequent_click: 25.7,
				rare_click: 25.6,
				name: "C-04"
			}, {
				serial_click: 27.9,
				frequent_click: 41.9,
				rare_click: 30.2,
				name: "C-03"
			},{
				serial_click: 47.5,
				frequent_click: 47.5,
				rare_click: 5,
				name: "C-02"
			}, {
				serial_click: 93.7,
				frequent_click: 0,
				rare_click: 6.3,
				name: "C-01"
			}];
			this.setData(mockData);
			this.showChart = true;

			this.setColors();
		},
		// Set the datasets of the chart
		setData(data) {
			this.chartData = {
				labels: [],
				active: [],
				datasets: []
			};
			this.chartLabels.forEach((label) => {
				const dataset = {};
				dataset.label = label;
				dataset.data = [];
				dataset.sent = [];
				dataset.clicked = [];
				dataset.backgroundColor = [];
				dataset.hoverBackgroundColor = [];
				dataset.hoverBorderColor = [];
				dataset.borderColor = [];
				dataset.borderWidth = 1;
				dataset.maxBarThickness = 150;
				this.chartData.datasets.push(dataset);
			});

			// Reverse dataset so that Smarter targets will appear closer to Cyber Chain x axis
			data.reverse().forEach((d) => {
				this.chartData.labels.push(d.name);
				this.chartData.datasets[this.getInvertedRiskGroup(this.riskGroup.Serial)].data.push(d.serial_click);
				this.chartData.datasets[this.getInvertedRiskGroup(this.riskGroup.Frequent)].data.push(d.frequent_click);
				this.chartData.datasets[this.getInvertedRiskGroup(this.riskGroup.Rare)].data.push(d.rare_click);
			});
		},
		getInvertedRiskGroup(riskGroup) {
			return riskGroup + (4 - 2 * riskGroup) -1;	
		},
		// Set the options for the chart
		setOptions() {
			this.options = {
				layout: {
					padding: {
						top: 0
					}
				},
				scales: {
					xAxes: [
						{
							stacked: true,
						}
					],
					yAxes: [
						{
							stacked: true,
							ticks: {
								min: 0,
								callback: function (value) {
									return ((value / this.max) * 100).toFixed(0) + "%";
								}
							},
							scaleLabel: {
								display: true
							}
						}
					]
				},
				legend: {
					display: true,
					labels: {
						fontColor: colors.plain.primary,
						fontSize: 16,
						padding: 20,
					}
				},
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					stacked100: { enable: true, replaceTooltipLabel: false },
					datalabels: {
						formatter: function (_value, context) {
							const data = context.chart.data;
							const { datasetIndex, dataIndex } = context;
							return data.calculatedData[datasetIndex][dataIndex] !== 0 ? data.calculatedData[datasetIndex][dataIndex].toFixed(1) + "%" : "";
						},
						display: function(context) {
							const data = context.chart.data;
							const { datasetIndex, dataIndex } = context;
							return data.calculatedData[datasetIndex][dataIndex] >= 4;
						},
						color: colors.plain.primary,
						clamp: true,
						clip: true,
						font: {
							size: 13
						}
					}
				}
			};
		},
		// Initialize the chart
		setColors() {
			this.chartData.datasets.forEach((dataset, datasetIndex) => {
				dataset.data.forEach((value, elementIndex) => {
					this.chartData.datasets[datasetIndex].backgroundColor[elementIndex] = colors.palette.riskGroup.alpha.dark[datasetIndex+1];
					this.chartData.datasets[datasetIndex].hoverBackgroundColor[elementIndex] = colors.palette.riskGroup.alpha.dark[datasetIndex+1];
					this.chartData.datasets[datasetIndex].hoverBorderColor[elementIndex] = colors.palette.riskGroup.alpha.dark[datasetIndex+1];
					this.chartData.datasets[datasetIndex].borderColor[elementIndex] = colors.palette.riskGroup.alpha.dark[datasetIndex+1];
				});
			});
		}
	}
}
</script>

<style lang="less">
.overlay.ccs {
    z-index: 999;
	width: calc(100% - 31px);
	border-radius: .25rem;
	margin: 0 16px;
	opacity: 0.2;
}
.padding-200 {
	height: 200px;
}
.overlay-text {
    z-index: 999;
	width: calc(100% - 31px);
	margin: 0 16px;
	height: 100%;
    position: absolute;
	text-align: center;
	word-break: break-word;
	font-size: 30px;
	font-weight: bold;
	.outline {
		-webkit-text-stroke: 1px #cac3c3;
		user-select: none;
	}
}
</style>