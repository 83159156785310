<template>
	<div v-if="hideRiskEvolution">
		<mock-up></mock-up>
	</div>
	<div v-else>
		<b-card id="cyberChainStrength" v-if="showChart">
			<b-card-title>
				<div class="title" :title="title">
					{{ title }}
					<font-awesome-icon id="riskEvolutionTooltip" :icon="['fas', 'info-circle']" class="mr-1"/>
					<b-tooltip class="mt-2 mr-2" triggers="hover" right target="riskEvolutionTooltip">
						<span v-html="$t('Dashboard.Tooltips.' + (this.myself == 'stacked100'? 'RiskEvolutionInfo' : 'ClickersPerCampaignInfo'))"></span>
					</b-tooltip>
				</div>
				<b-col class="filter-container">
					<span v-html="breadCrumb" @click="removeFilter"></span>
				</b-col>
				<b-dropdown v-if="!inProgress" right class="pie-dropdown">
					<template v-slot:button-content>
						<label class="sr-only">{{ $t('Dashboard.Charts.RiskEvolution') }}</label>
						<font-awesome-icon :icon="['fas', 'ellipsis-v']" />
					</template>
					<b-dropdown-item @click="handleSwitch('stacked100')">{{ $t('Dashboard.Charts.RiskEvolution') }}</b-dropdown-item>
					<b-dropdown-item @click="handleSwitch('absolute')">{{ $t('Dashboard.Charts.ClickersPerCampaign') }}</b-dropdown-item>
				</b-dropdown>
			</b-card-title>
			<cg-bar-chart :chart-data="chartData" :options="options" :height="450" ref="chart"></cg-bar-chart>
			<template v-if="hasRemediation" #footer>
				{{ remediationLegend }}
			</template>
		</b-card>
	</div>
</template>

<script> 
import BarChart from "@/components/charts/wrappers/BarChart";
import MockUp from "@/components/dashboard/cards/CCSMockUp";
import colors from "@/components/charts/utils/colors";

export default {
	components: {
		"cg-bar-chart": BarChart,
		MockUp
	},
	data() {
		return {
			title: this.$t("Dashboard.Charts.RiskEvolution"),
			myself: "stacked100",
			breadCrumb: "",
			chartData: {},
			chartLabels: [],
			options: {},
			inProgress: false,
			showChart: false,
			hideRiskEvolution: false,
			reportingEnabled: false,
			hasRemediation: false,
			remediationLegend: '® ' + this.$t('Dashboard.Charts.CampaignRemediationLegend')
		};
	},
	props: {
		cds: Object
	},
	async mounted() {
		this.cds.removeAllFilters();
		this.cds.removeAllTransforms();

		this.reportingEnabled = await this.cds.getReportEnabled();

		this.setOptions();

		if(this.reportingEnabled) {
			this.chartLabels.push(this.$t('Dashboard.Charts.Defender'));
		}

		this.chartLabels.push(this.$t('Dashboard.Charts.Strong'), this.$t('Dashboard.Charts.Intermediate'), this.$t('Dashboard.Charts.Weak'));

		this.cds.getBus().$on('cds_refresh_start', () => {
			this.inProgress = true;
		});

		this.cds.getBus().$on('cds_refresh_end', () => {
			this.refresh();
		});

		this.cds.getBus().$on("filter_changed", () => {
			this.breadCrumb = this.cds.getInteractiveBreadcrumb();
			this.dispatchEvent();
		});

		let hideEvolution = await this.cds.getHideRiskEvolution();

		let campaignData = await this.cds.getRawData();

		// Define whether the main graph should be hidden in case the third Campaign isn't over yet
		if(hideEvolution && (campaignData && campaignData.length <= 2)) {
			this.hideRiskEvolution = true;
		}
	},
	methods: {
		async getData() {
			this.inProgress = false;

			let data = await this.cds.getData();

			data = this.cds.applyTransform(data, this.cds.CdsXform.SummarizeCampaign);

			data.forEach((c) => {
				c.serial_click = c.stats[this.cds.RiskGroup.Serial].clicked;
				c.frequent_click = c.stats[this.cds.RiskGroup.Frequent].clicked;
				c.rare_click = c.stats[this.cds.RiskGroup.Rare].clicked;
				c.defender_click = c.stats[this.cds.RiskGroup.Defender].reported;
				
				c.serial_click_ccs = c.active_stats[this.cds.RiskGroup.Serial] || 0;
				c.frequent_click_ccs = c.active_stats[this.cds.RiskGroup.Frequent] || 0;
				c.rare_click_ccs = c.active_stats[this.cds.RiskGroup.Rare] || 0;
				c.defender_click_ccs = c.active_stats[this.cds.RiskGroup.Defender] || 0;
			});

			this.showChart = data ? true : false;
			return data;
		},
		async refresh() {
			let data = await this.getData();

			if (data.length > 0) {
				this.createChart(data);

				if (this.cds.switched) {
					this.switchChart();
				} else {
					this.dispatchEvent();
				}
			}
		},
		dispatchEvent() {
			if (this.cds.selectedRiskIndex) {
				this.toggleDataset();
			} else {
				this.toggleBar();
			}
			this.$refs.chart.$data._chart.update();
		},
		createChart(data) {
			this.setData(data);
			this.render();
			this.createLegend();
		},
		// Set the datasets of the chart
		setData(data) {
			this.chartData = {
				labels: [],
				active: [],
				campaignId: [],
				datasets: []
			};
			this.chartLabels.forEach((label) => {
				const dataset = {};
				dataset.label = label;
				dataset.data = [];
				dataset.ccs = []; // Cyber Chain Strenght, now called Risk Evolution
				dataset.sent = [];
				dataset.sent_ccs = [];
				dataset.clicked = [];
				dataset.period = [];
				dataset.active = null;
				dataset.backgroundColor = [];
				dataset.hoverBackgroundColor = [];
				dataset.hoverBorderColor = [];
				dataset.borderColor = [];
				dataset.borderWidth = 1;
				dataset.maxBarThickness = 150;
				this.chartData.datasets.push(dataset);
			});

			// Reverse dataset so that Smarter targets will appear closer to Cyber Chain x axis
			data.reverse().forEach((d) => {
				if(d.remediated) {
					this.hasRemediation = true;
					this.chartData.labels.push(d.name + ' ® ');
				} else {
					this.chartData.labels.push(d.name);
				}
				this.chartData.campaignId.push(d.campaign_id);

				this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Serial)].data.push(d.serial_click);
				this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Frequent)].data.push(d.frequent_click);

				this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Serial)].ccs.push(d.serial_click_ccs);
				this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Frequent)].ccs.push(d.frequent_click_ccs);

				this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Serial)].sent.push(d.stats[this.cds.RiskGroup.Serial].sent);
				this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Frequent)].sent.push(d.stats[this.cds.RiskGroup.Frequent].sent);

				this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Serial)].clicked.push(d.stats[this.cds.RiskGroup.Serial].clicked);
				this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Frequent)].clicked.push(d.stats[this.cds.RiskGroup.Frequent].clicked);

				let planned_start = this.$moment.utc(d.planned_start).local().locale(this.$i18n.locale).format("YYYY-MM-DD");
				let planned_end = this.$moment.utc(d.planned_end).local().locale(this.$i18n.locale).format("YYYY-MM-DD");

				this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Serial)].period.push(planned_start + " → " + planned_end);
				this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Frequent)].period.push(planned_start + " → " + planned_end);
				this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Rare)].period.push(planned_start + " → " + planned_end);

				if(this.reportingEnabled) {
					// Reporting phishing enabled, show Defenders in both charts
					this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Rare)].data.push(d.rare_click);
					this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Defender)].data.push(d.defender_click);

					this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Rare)].ccs.push(d.rare_click_ccs);
					this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Defender)].ccs.push(d.defender_click_ccs);

					this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Rare)].sent.push(d.stats[this.cds.RiskGroup.Rare].sent);
					this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Defender)].sent.push(d.stats[this.cds.RiskGroup.Defender].sent);

					this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Rare)].clicked.push(d.stats[this.cds.RiskGroup.Rare].clicked);
					this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Defender)].clicked.push(d.stats[this.cds.RiskGroup.Defender].clicked);

					this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Defender)].period.push(planned_start + " → " + planned_end);
				} else {
					// Reporting disabled, merge Rare and Defenders in %charts, hide Defenders in absolute value chart 
					this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Rare)].data.push(d.rare_click);

					this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Rare)].ccs.push(d.rare_click_ccs + d.defender_click_ccs);

					// Cumulative %chart contains info about Rare+Defender targets, while chart with absolute value does NOT contain any info about Defenders
					this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Rare)].sent.push(d.stats[this.cds.RiskGroup.Rare].sent);
					this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Rare)].sent_ccs.push(d.stats[this.cds.RiskGroup.Rare].sent + d.stats[this.cds.RiskGroup.Defender].sent);

					this.chartData.datasets[this.getInvertedRiskGroup(this.cds.RiskGroup.Rare)].clicked.push(d.stats[this.cds.RiskGroup.Rare].clicked + d.stats[this.cds.RiskGroup.Defender].clicked);
				}
			});
		},
		getInvertedRiskGroup(riskGroup) {
			/**
			 * Previous Risk Group mapping was trivial: to match the position in the datasets array, simply shift by 1.
			 * To reverse the dataset, leverage on the function y = 4 - 2x that returns the current values:
			 * 
			 * Serial: 1 -> 3
			 * Frequent: 2 -> 2
			 * Rare: 3 -> 1
			 * Defender: 4 -> 0
			 * 
			 * When reporting is not enabled, then shift the value returned by 1
			 */
			if(this.reportingEnabled) {
				return riskGroup + (4 - 2 * riskGroup);
			} else {
				return riskGroup + (4 - 2 * riskGroup) -1;
			}
		},
		// Set the options for the chart
		setOptions() {
			this.options = {
				layout: {
					padding: {
						top: 0
					}
				},
				scales: {
					xAxes: [
						{
							stacked: true,
						}
					],
					yAxes: [
						{
							stacked: true,
							ticks: {
								min: 0,
								callback: function (value) {
									return ((value / this.max) * 100).toFixed(0) + "%";
								}
							},
							scaleLabel: {
								display: true
							}
						}
					]
				},
				legend: {
					display: true,
					labels: {
						fontColor: colors.plain.primary,
						fontSize: 16,
						padding: 20,
					},
					onClick: this.handleLegendClick, // Register the click event on the legend
				},
				responsive: true,
				maintainAspectRatio: false,
				animation: {
					animateRotate: true,
					animateScale: true
				},
				tooltips: {
					backgroundColor: colors.plain.white,
					titleFontColor: colors.plain.primary,
					bodyFontColor: colors.plain.primary,
					borderColor: colors.plain.grey,
					borderWidth: 0.5,
					cornerRadius: 10,
					position: 'custom',
					xPadding: 15,
					yPadding: 15,
					callbacks: {
						beforeBody: (tooltipItem, data) => {
							let body = [];

							let datasetIndex = tooltipItem[0].datasetIndex;

							body.push("");
							
							// Clicks should be hidden for Cyber Defenders
							if(!this.cds.switched && datasetIndex > 0) {
								let index = tooltipItem[0].index;
								let category = data.datasets[datasetIndex].label;
								let percentage = data.calculatedData[datasetIndex][index];
								let active = data.datasets[datasetIndex].ccs[index];
								let label_string = category + ": " + active + (percentage !== 0 ? " (" + percentage.toFixed(1) + "%)" : "");
								
								body.push(label_string);

								body.push("");
							}

							return body;
						},
						afterBody: (tooltipItem, data) => {
							let body = [];

							let datasetIndex = tooltipItem[0].datasetIndex;
							let index = tooltipItem[0].index;
							let period = data.datasets[datasetIndex].period[index];

							// Clicks should be hidden for Cyber Defenders
							if(this.cds.switched && datasetIndex > 0) {
								
								let sent = data.datasets[datasetIndex].sent[index];
								let clicked = data.datasets[datasetIndex].clicked[index];
								
								body.push("");

								body.push(this.$t('Dashboard.Charts.CCSClicks') + ": " + clicked + " (" + (100 * (clicked / sent)).toFixed(1) + "% " + data.datasets[datasetIndex].label + ")");
							}

							body.push("");
							body.push(this.$t('Dashboard.Charts.CampaignPeriod') + ": " + period);

							return body;
						}
					}
				},
				onHover: (event, chartElement) => {
					event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
				},
				plugins: {
					stacked100: { enable: true, replaceTooltipLabel: false },
					datalabels: {
						formatter: function (_value, context) {
							const data = context.chart.data;
							const { datasetIndex, dataIndex } = context;
							return data.calculatedData[datasetIndex][dataIndex] !== 0 ? data.calculatedData[datasetIndex][dataIndex].toFixed(1) + "%" : "";
						},
						display: function(context) {
							const data = context.chart.data;
							const { datasetIndex, dataIndex } = context;
							return data.calculatedData[datasetIndex][dataIndex] >= 4;
						},
						color: colors.plain.primary,
						clamp: true,
						clip: true,
						font: {
							size: 13
						}
					}
				}
			};
		},
		// Initialize the chart
		render() {
			// Register the click event on the chart
			var canvas = this.$refs.chart.$data._chart.canvas;
			canvas.onclick = this.handleClick;

			// Set the defaults colors and the status for each element of the chart
			const isolateRisk = this.cds.selectedRiskIndex;

			this.chartData.datasets.forEach((dataset, datasetIndex) => {
				dataset.data.forEach((value, elementIndex) => {
					this.highlightDataset(datasetIndex, elementIndex, !isolateRisk ? true : false);
				});
			});
		},
		// Override the legend with custom colors
		createLegend(absolute) {
			this.$refs.chart.addPlugin({
				id: !absolute ? 'stacked-legend' : 'absolute-legend',
				beforeDraw: (chart) => {
					var legendItems = chart.legend.legendItems;
					legendItems.forEach((item, index) => {
						if(!this.reportingEnabled) {
							// Chart Legend starts with Defenders blue color, so shift the index by 1 to start with the Strong green
							item.fillStyle = !absolute ? colors.palette.riskGroup.alpha.dark[index+1] : colors.alpha.blue;
							item.strokeStyle = !absolute ? colors.palette.riskGroup.plain.dark[index+1] : colors.alpha.blue;
						} else {
							// Show Defenders also, use the Legend palette as usual
							item.fillStyle = !absolute ? colors.palette.riskGroup.alpha.dark[index] : colors.alpha.blue;
							item.strokeStyle = !absolute ? colors.palette.riskGroup.plain.dark[index] : colors.alpha.blue;
						}
					});
				}
			});
		},
		// Highligth the selected bar
		highlightBar(elementIndex, active) {
			this.chartData.datasets.forEach((dataset, index) => {
				if(!this.reportingEnabled) {
					// Shift the index by 1 to start the Legend palette from the Strong green, not from the Defenders blue since they should be hidden
					dataset.backgroundColor[elementIndex] = active ? colors.palette.riskGroup.alpha.dark[index+1] : colors.palette.riskGroup.alpha.light[index+1];
					dataset.hoverBackgroundColor[elementIndex] = colors.palette.riskGroup.alpha.dark[index+1];
					dataset.hoverBorderColor[elementIndex] = colors.palette.riskGroup.alpha.dark[index+1];
					dataset.borderColor[elementIndex] = active ? colors.palette.riskGroup.alpha.dark[index+1] : colors.palette.riskGroup.alpha.light[index+1];
				} else {
					// Defenders are shown, use all the Legend palette
					dataset.backgroundColor[elementIndex] = active ? colors.palette.riskGroup.alpha.dark[index] : colors.palette.riskGroup.alpha.light[index];
					dataset.hoverBackgroundColor[elementIndex] = colors.palette.riskGroup.alpha.dark[index];
					dataset.hoverBorderColor[elementIndex] = colors.palette.riskGroup.alpha.dark[index];
					dataset.borderColor[elementIndex] = active ? colors.palette.riskGroup.alpha.dark[index] : colors.palette.riskGroup.alpha.light[index];
				}
			});
		},
		// Highligth the selected segment of the bar
		highlightDataset(datasetIndex, elementIndex, active) {
			if(!this.reportingEnabled) {
				// Same as above, shift the index by 1 to get the right color for the selected Risk Group
				this.chartData.datasets[datasetIndex].backgroundColor[elementIndex] = active ? colors.palette.riskGroup.alpha.dark[datasetIndex+1] : colors.palette.riskGroup.alpha.light[datasetIndex+1];
				this.chartData.datasets[datasetIndex].hoverBackgroundColor[elementIndex] = active ? colors.palette.riskGroup.alpha.dark[datasetIndex+1] : colors.palette.riskGroup.alpha.light[datasetIndex+1];
				this.chartData.datasets[datasetIndex].hoverBorderColor[elementIndex] = active ? colors.palette.riskGroup.alpha.dark[datasetIndex+1] : colors.palette.riskGroup.alpha.light[datasetIndex+1];
				this.chartData.datasets[datasetIndex].borderColor[elementIndex] = active ? colors.palette.riskGroup.alpha.dark[datasetIndex+1] : colors.palette.riskGroup.alpha.light[datasetIndex+1];
			} else {
				// Since we show also the Defenders, use all the Legend palette as usual
				this.chartData.datasets[datasetIndex].backgroundColor[elementIndex] = active ? colors.palette.riskGroup.alpha.dark[datasetIndex] : colors.palette.riskGroup.alpha.light[datasetIndex];
				this.chartData.datasets[datasetIndex].hoverBackgroundColor[elementIndex] = active ? colors.palette.riskGroup.alpha.dark[datasetIndex] : colors.palette.riskGroup.alpha.light[datasetIndex];
				this.chartData.datasets[datasetIndex].hoverBorderColor[elementIndex] = active ? colors.palette.riskGroup.alpha.dark[datasetIndex] : colors.palette.riskGroup.alpha.light[datasetIndex];
				this.chartData.datasets[datasetIndex].borderColor[elementIndex] = active ? colors.palette.riskGroup.alpha.dark[datasetIndex] : colors.palette.riskGroup.alpha.light[datasetIndex];
			}
		},
		// Handle the click event
		handleClick(evt) {
			const chart = this.$refs.chart.$data._chart;
			let activeElement = chart.getElementAtEvent(evt);

			if (activeElement.length > 0) {
				activeElement = activeElement[0];
				if (!this.cds.legendItemActive) {
					if (!this.chartData.active[activeElement._index] && this.cds.selectedCampaignIndex != activeElement._index) {
						this.handleFirstClick(activeElement._index);
					}
				} else {
					this.handleLegendCampaignClick(activeElement._index);
				}
				this.cds.emitFilterChanges();
			}
		},
		handleFirstClick(elementIndex) {
			const reset = this.cds.selectedCampaignIndex == elementIndex;
			this.cds.selectedCampaignIndex = reset ? null : elementIndex;
			this.cds.selectedRiskIndex = null;
			if (this.cds.filter.risk) {
				this.cds.setFilter(this.cds.Filter.risk, null);
			}
			this.cds.setFilter(this.cds.Filter.campaign, reset ? null : this.chartData.campaignId[elementIndex], this.chartData.labels[elementIndex]);
		},
		handleLegendCampaignClick(elementIndex) {
			this.cds.selectedCampaignIndex = elementIndex;
			this.cds.setFilter(this.cds.Filter.campaign, this.chartData.campaignId[elementIndex], this.chartData.labels[elementIndex]);
		},
		// Toggle active status of the bar
		toggleBar() {
			if (this.cds.legendItemActive && this.cds.filter.risk_group === null) {
				this.toggleLegend(false);
			} else {
				const elementIndex = this.cds.selectedCampaignIndex;
				const myselfActive = this.cds.filter[this.cds.Filter.campaign];

				this.chartData.datasets.forEach((dataset) => {
					dataset.data.forEach((value, index) => {
						this.chartData.active[index] = (index == elementIndex) && myselfActive;
						this.highlightBar(index, myselfActive ? this.chartData.active[index] : true);
					});
				});

				if (myselfActive) {
					if (!this.cds.legendItemActive) {
						this.cds.removeTransform(this.cds.CdsXform.IsolateCampaign);
						this.cds.removeTransform(this.cds.CdsXform.IsolateRiskGroup);
					} else {
						if (this.cds.hasTransform(this.cds.CdsXform.IsolateCampaign)) {
							this.cds.removeTransform(this.cds.CdsXform.IsolateCampaign);
							this.cds.addTransform(this.cds.CdsXform.IsolateRiskGroup, 20, this.cds.selectedRiskGroup);
						}
					}
					setTimeout(() => {
						this.cds.addTransform(this.cds.CdsXform.IsolateCampaign, 10, { name: this.chartData.labels[elementIndex], id: this.chartData.campaignId[elementIndex] });
					}, 100);
				} else {
					this.cds.removeTransform(this.cds.CdsXform.IsolateCampaign);
				}
			}
		},
		// Toggle active status of the segment
		toggleDataset() {
			const elementIndex = this.cds.selectedCampaignIndex;
			const datasetIndex = this.chartLabels.length - this.cds.selectedRiskIndex;
			const myselfActive = Number.isInteger(this.cds.filter[this.cds.Filter.risk]);
			this.chartData.active[elementIndex] = false;

			this.chartData.datasets.forEach((dataset, index) => {
				dataset.active = ((index == datasetIndex) && myselfActive) ? true : false;
				this.highlightDataset(index, elementIndex, myselfActive ? dataset.active : true);
			});

			if (myselfActive) {
				this.cds.removeTransform(this.cds.CdsXform.IsolateCampaign);
				this.cds.addTransform(this.cds.CdsXform.IsolateRiskGroup, 20, datasetIndex + 1);
				setTimeout(() => {
					this.cds.addTransform(this.cds.CdsXform.IsolateCampaign, 10, { name: this.chartData.labels[elementIndex], id: this.chartData.campaignId[elementIndex] });
				}, 100);
			} else {
				this.cds.removeTransform(this.cds.CdsXform.IsolateCampaign);
				this.cds.removeTransform(this.cds.CdsXform.IsolateRiskGroup);
			}
		},
		// Handle the click event on the legend
		handleLegendClick(e, legendItem) {
			let index = legendItem.datasetIndex;
			let chart = this.$refs.chart.$data._chart;
			let alreadyHidden = !chart.getDatasetMeta(index).hidden ? false : chart.getDatasetMeta(index).hidden;

			this.cds.selectedRiskGroup = this.getInvertedRiskGroup(index);
			this.cds.selectedRiskIndex = null;
			this.cds.setFilter(this.cds.Filter.risk, null);
			this.toggleLegend(alreadyHidden);
		},
		toggleLegend(alreadyHidden) {
			let chart = this.$refs.chart.$data._chart;

			if (this.cds.switched) {
				this.cds.backToStacked100 = true;
			}

			this.scaleToAbsolute();

			// Check if the legend item is already selected
			if (!alreadyHidden && this.cds.legendItemActive) {
				this.cds.legendItemActive = false;
				this.setOptions();

				// If filter has been applied to %layout, display it again after filter removal
				if(this.cds.backToStacked100) {
					this.cds.backToStacked100 = false;
					this.scaleToStacked100();
				} else {
					this.scaleToAbsolute();
				}

				// Re-enforce labels optional display for smaller values
				// this.options.plugins.datalabels.display = function(context) {
				// 	return context.dataset.data[context.dataIndex] >= 4;
				// };

				this.$refs.chart.renderChart(this.chartData, this.options);
				this.cds.removeFilter(this.cds.Filter.riskGroup);
				this.cds.removeTransform(this.cds.CdsXform.IsolateRiskGroup);
			} else {
				this.cds.setFilter(this.cds.Filter.riskGroup, this.cds.selectedRiskGroup, this.chartLabels[this.getInvertedRiskGroup(this.cds.selectedRiskGroup)]);

				// Override the default legend click behavior
				chart.data.datasets.forEach((dataset, datasetindex) => {
					let meta = chart.getDatasetMeta(datasetindex);

					// Toggle the legend item
					if (chart.data.datasets.length === 1) {
						meta.hidden = !meta.hidden;
					} else {
						meta.hidden = !alreadyHidden && meta.hidden ? !meta.hidden && !alreadyHidden : datasetindex !== this.getInvertedRiskGroup(this.cds.selectedRiskGroup);
					}
				});

				this.cds.legendItemActive = true;
				this.cds.addTransform(this.cds.CdsXform.IsolateRiskGroup, 10, this.cds.selectedRiskGroup);

				// Enable labels display even for smaller values
				// this.options.plugins.datalabels.display = true;

				this.$refs.chart.$data._chart.update();
			}
			this.cds.emitFilterChanges();
		},
		// Scale the chart to 100 % stacked bar chart
		scaleToStacked100() {
			this.cds.switched = true;
			this.options.tooltips.callbacks.label = (tooltipItem, data) => {
				let category = data.datasets[tooltipItem.datasetIndex].label;
				let percentage = data.calculatedData[tooltipItem.datasetIndex][tooltipItem.index];
				let active = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
				return category + ": " + active + (percentage !== 0 ? " (" + percentage.toFixed(1) + "%)" : "");
			};
			this.options.tooltips.callbacks.title = (tooltipItem) => {
				return this.$t('Dashboard.Charts.ActivePopulation', { param: tooltipItem[0].label });
			};
			this.options.plugins.stacked100.enable = true;
			this.options.scales.yAxes[0].ticks = {
				callback: function (value) {
					return ((value / this.max) * 100).toFixed(0) + "%";
				}
			};
			this.options.plugins.datalabels.formatter = function (_value, context) {
				const data = context.chart.data;
				const { datasetIndex, dataIndex } = context;
				return data.calculatedData[datasetIndex][dataIndex] !== 0 ? data.calculatedData[datasetIndex][dataIndex].toFixed(1) + "%" : "";
			};
			this.options.plugins.datalabels.display = function(context) {
				const data = context.chart.data;
				const { datasetIndex, dataIndex } = context;
				return data.calculatedData[datasetIndex][dataIndex] >= 4;
			};
		},
		scaleToAbsolute() {
			this.cds.switched = false;
			this.options.tooltips.callbacks.label = (tooltipItem, data) => {
				let category = data.datasets[tooltipItem.datasetIndex].label;
				let percentage = data.calculatedData[tooltipItem.datasetIndex][tooltipItem.index];
				let sent = data.datasets[tooltipItem.datasetIndex].sent[tooltipItem.index];
				let clicked = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
				if(tooltipItem.datasetIndex > 0) {
					return this.$t('Dashboard.Charts.CCSClicks') + ": " + clicked + " (" + (100 * (clicked / sent)).toFixed(1) + "% " + category + ")";
				} else return category + ": " + clicked + (percentage !== 0 ? " (" + percentage.toFixed(1) + "%)" : "");
			};
			this.options.plugins.stacked100.enable = false;
			this.options.plugins.datalabels.formatter = function (value) {
				return value !== 0 ? value : "";
			};
			this.options.plugins.datalabels.display = function(context) {
				const data = context.chart.data;
				/**
				 * In order to properly show segment labels when there is enough room,
				 * calculate the max height value and make the proportion
				 */

				let datasources = data.datasets.map(i => i.data);

				let sum = datasources.reduce(function (result, row) {
					row.forEach(function (val, i) {
						result[i] = (result[i] || 0) + val;
					});
					return result;
				}, []);

				let max = 0;
				for (let i = 0; i < sum.length; ++i) {
					if (sum[i] > max) {
						max = sum[i];
					}
				}
				
				return (context.dataset.data[context.dataIndex] * 100) / max >= 4;
			};
			this.options.scales.yAxes[0].ticks.callback = function (value) {
				return value;
			};

			this.$refs.chart.$data._chart.options = this.options;
		},
		resetChart() {
			this.cds.selectedCampaignIndex = null;
			this.cds.selectedRiskIndex = null;
			this.cds.removeFilter(this.cds.Filter.campaign);
			this.cds.removeFilter(this.cds.Filter.risk);
			this.cds.removeFilter(this.cds.Filter.riskGroup);
			this.cds.removeAllTransforms();
		},
		removeFilter(event) {
			if (event.target.classList.contains("close")) {
				this.cds.removeFilter(event.target.getAttribute("filter"));
				this.cds.emitFilterChanges();
			}
		},
		// Handle the switch event 
		handleSwitch(myself) {
			if(this.myself == myself) {
				// Prevent chart reload if the option is the very same already displayed
				return;
			}

			if (this.cds.selectedRiskIndex) {
				this.cds.removeFilter(this.cds.Filter.risk);
				this.cds.emitFilterChanges();
			}

			if (this.cds.legendItemActive) {
				this.cds.legendItemActive = false;
				this.cds.removeFilter(this.cds.Filter.riskGroup);
				this.cds.removeTransform(this.cds.CdsXform.IsolateRiskGroup);
				this.cds.emitFilterChanges();
			}
			
			if (!this.cds.switched) {
				this.switchChart();
			} else {
				this.scaleToAbsolute();
				this.myself = "absolute";
				this.swapDataset();
			}

			this.title = this.myself == "stacked100"? this.$t("Dashboard.Charts.RiskEvolution") : this.$t("Dashboard.Charts.ClickersPerCampaign");
			this.$refs.chart.renderChart(this.chartData, this.options);
		},
		// Switch the chart to 100 % stacked bar chart
		switchChart() {
			this.myself = "stacked100";
			this.swapDataset();
			
			this.scaleToStacked100();
			this.toggleBar();
		},
		swapDataset() {
			this.chartData.datasets.forEach((el) => {
				let temp = el.data;
				el.data = el.ccs;
				el.ccs = temp;
			});
		}
	}
}
</script>

<style lang="less">
#cyberChainStrength {
	min-height: 478px;
	.filter-container {
		padding-right: 15px !important;
	}
	.switch-icon {
		position: absolute;
		right: 15px;
		cursor: pointer;
		font-size: 1rem;
	}
	.card-footer {
		background-color: #ffffff;
		border: none;
		font-size: 0.8em;
	}
}
</style>