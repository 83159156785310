<template>
	<b-card id="riskByOrgLevel">
		<b-card-title>
			<div class="title" :title="title">
				{{ title }}
				<font-awesome-icon id="riskByOrgLevelTooltip" :icon="['fas', 'info-circle']" class="mr-1"/>
				<b-tooltip class="mt-2 mr-2" triggers="hover" right target="riskByOrgLevelTooltip">
					<span v-html="$t('Dashboard.Tooltips.RiskByOrgLevelInfo')"></span>
				</b-tooltip>
			</div>
			<b-col class="filter-container">
				<span v-html="breadCrumb" @click="removeFilter"></span>
			</b-col>
			<b-dropdown v-if="!inProgress && showMenu" right class="pie-dropdown">
				<template v-slot:button-content>
					<label class="sr-only">{{ $t('Analytics.ClickRateBy') }}</label>
					<font-awesome-icon :icon="['fas', 'ellipsis-v']" />
				</template>
				<b-dropdown-item v-if="companyData['use_org_1']" @click="selectChart" myself="org_1">{{ fetchOrgName("org_1") }}</b-dropdown-item>
				<b-dropdown-item v-if="companyData['use_org_2']" @click="selectChart" myself="org_2">{{ fetchOrgName("org_2") }}</b-dropdown-item>
				<b-dropdown-item v-if="companyData['use_org_3']" @click="selectChart" myself="org_3">{{ fetchOrgName("org_3") }}</b-dropdown-item>
				<b-dropdown-item v-if="companyData['use_org_4']" @click="selectChart" myself="org_4">{{ fetchOrgName("org_4") }}</b-dropdown-item>
			</b-dropdown>
		</b-card-title>
		<b-row class="mt-5 pl-3 d-flex chart-container">
			<b-col :cols="12 - legendSize">
				<cg-doughnut-chart
					v-if="showChart && !isEmpty"
					:chart-data="chartData"
					:options="options"
					:height="350"
					ref="chart"
				></cg-doughnut-chart>
			</b-col>
			<b-col class="legend-container">
				<span class="html-legend" v-html="htmlLegend" ref="legend"></span>
			</b-col>
		</b-row>
		<div v-if="isEmpty" class="empty-message risk-org my-2">{{ $t('Analytics.Table.Empty') }}</div>
	</b-card>
</template>

<script> 
import DoughnutChart from "@/components/charts/wrappers/DoughnutChart";
import CompanyService from "@/services/company.service";
import colors from "@/components/charts/utils/colors";
import { randomColorGenerator } from "@/components/charts/utils/colors_generator";
import { PieChartOptions } from "@/components/charts/utils/options";

export default {
	components: {
		"cg-doughnut-chart": DoughnutChart
	},
	data() {
		return {
			title: this.$t('Dashboard.Charts.RiskBy'),
			myself: "org_1",
			selectedIndex: null,
			breadCrumb: "",
			chartData: {},
			options: {},
			inProgress: false,
			showChart: false,
			isEmpty: false,
			myselfClicked: false,
			companyData: {
				'org_1': null,
				'org_2': null,
				'org_3': null,
				'org_4': null
			},
			htmlLegend: null,
			darkPalette: [],
			lightPalette: [],
			legendSize: 6,
			visibleOrgs: []
		};
	},
	props: {
		cds: Object,
		maxSliceSize: {
			type: Number,
			required: true,
			default: 9
		}
	},
	computed: {
		showMenu() {
			return this.visibleOrgs && this.visibleOrgs.length > 1;
		}
	},
	async mounted() {
		if(this.cds.companyId) {
			await CompanyService.getCompany(this.cds.companyId).then((res) => {
				this.companyData = res.data;

				// Valuate which org should be shown first
				this.visibleOrgs = [];
				Object.keys(this.companyData).filter((k) => { return k.match(/use_org_(\d)/g); })
					.forEach(key => {
						if(this.companyData[key]) {
							this.visibleOrgs.push(key.replace("use_", ""));
						}
					});

					if(this.visibleOrgs && !this.visibleOrgs.length) {
						this.isEmpty = true;
					} else {
						// Filter orgs not used in filters
						let firstOrg = this.visibleOrgs.filter((o) => {  if(this.$account.filtered_orgs[this.cds.companyId]) return !this.$account.filtered_orgs[this.cds.companyId][o] });
						// Assign first org "available"
						this.myself = firstOrg && firstOrg.length > 0 ? firstOrg[0] : this.visibleOrgs[0];
					}
			}).catch((error) => {
				console.error("RiskByOrgLevel - getCompany - error", error);
				this.isEmpty = true;
			});
		}

		this.init();

		this.cds.getBus().$on("filter_changed", () => {
			this.breadCrumb = this.cds.getInteractiveBreadcrumb();
			this.refresh();
		});

		this.$on("chartChanged", (myself) => {
			this.myself = myself;
			this.title = this.$t('Dashboard.Charts.RiskBy', { param: this.fetchOrgName(this.myself) });

			this.init();
			this.refresh();
		});
	},
	updated() {
		if (this.$refs.legend.childNodes.length > 0) {
			this.$refs.legend.childNodes[0].childNodes.forEach(li => {
				li.addEventListener("click", this.legendClickCallback, false);
			});
		}
	},
	methods: {
		async init() {
			let data = await this.getData();
			this.title = this.$t('Dashboard.Charts.RiskBy', { param: this.fetchOrgName(this.myself) });

			if (data && Object.keys(data).length > 0) {
				this.createChart(data);
			} else {
				this.isEmpty = true;
			}
		},
		async refresh() {
			let data = !this.myselfClicked ? await this.getData() : null;

			if (this.cds.hasOtherValidFilters(this.myself)) {
				if (data) this.addDataset(data);
			} else {
				this.removeDataset();
			}
			this.toggleActive();
		},
		// Retrieve the clicker rate
		async getData() {
			this.inProgress = true;

			const filters = this.cds.getFiltersFor(this.myself);
			const queryParams = filters ? filters : {};
			queryParams.ctx = this.cds.ctx;

			let result = this.cds.companyId ? await CompanyService.getOrgLevelRisk(this.cds.companyId, this.myself, queryParams) : {};
			
			// Groups all small entries in one slice named 'other'
			if(result.data.length > this.maxSliceSize) {
				result.data = result.data.sort((datum_a, datum_b) => datum_b.n_clicked - datum_a.n_clicked);
				let otherEntries = { 
					[this.myself]: this.$t('Roles.Other'),
					n_sent: 0, 
					n_clicked: 0
				}; 
				result.data.slice(this.maxSliceSize-1).forEach((datum) => {
					otherEntries.n_sent += datum.n_sent;
					otherEntries.n_clicked += datum.n_clicked;
				})
				result.data = [...result.data.slice(0, this.maxSliceSize-1), otherEntries];
			}
			
			result.data = result.data.sort((elem_a, elem_b) => elem_b.n_clicked - elem_a.n_clicked);

			this.inProgress = false;
			this.showChart = result.data ? true : false;
			return result.data;
		},
		fetchOrgName(org) {
			return this.companyData && this.companyData[org] ? this.companyData[org] : org;
		},
		createChart(data) {
			this.chartData = {
				labels: [],
				datasets: []
			};
			this.createPalette(data);
			this.addDataset(data);
			this.setOptions();
			this.addClickHandler();
			this.createLegend();
		},
		createPalette(data) {
			this.darkPalette = colors.palette.dark.slice();
			this.lightPalette = colors.palette.light.slice();

			const colorGenDark = randomColorGenerator(0.38);
			const colorGenLight = randomColorGenerator(0.08);

			data.forEach((d, index) => {
				if (index >= this.darkPalette.length) {
					this.darkPalette.push(colorGenDark.next().value);
				}
				if (index >= this.lightPalette.length) {
					this.lightPalette.push(colorGenLight.next().value);
				}
			});
		},
		addDataset(data) {
			const first = this.chartData.datasets.length == 0;

			if (first) {
				let dataset = {
					data: [],
					active: [],
					backgroundColor: [],
					hoverBackgroundColor: [],
					borderWidth: 1
				};
				data.forEach((d, index) => {
					dataset.backgroundColor.push(this.darkPalette[index]);
					dataset.hoverBackgroundColor.push(this.darkPalette[index]);
					dataset.data.push(d.n_clicked);
					this.chartData.labels.push(d[this.myself]);
					dataset.active.push(false);
				});

				this.chartData.datasets[0] = dataset;
			} else {
				let dataset = {
					data: this.chartData.datasets[0].data.map(() => null),
					backgroundColor: this.chartData.datasets[0].backgroundColor.map((b, index) => this.darkPalette[index]),
					hoverBackgroundColor: this.chartData.datasets[0].hoverBackgroundColor.map((b, index) => this.darkPalette[index]),
					borderWidth: 1
				};

				data.forEach(d => {
					const idx = this.chartData.labels.indexOf(d[this.myself]);
					dataset.data[idx] = d.n_clicked;
				});

				this.chartData.datasets[1] = dataset;
			}
		},
		removeDataset() {
			if (this.chartData.datasets[1]) {
				this.chartData.datasets.pop();
			}
		},
		// Register the click event on the chart
		addClickHandler() {
			var canvas = this.$refs.chart.$data._chart.canvas;
			canvas.onclick = this.handleClick;
			canvas.onmousemove = this.handleHover;
		},
		createLegend() {
			this.$refs.chart.addPlugin({
				id: 'custom-legend',
				beforeDraw: (chart) => {
					this.htmlLegend = chart.generateLegend();
				}
			});
		},
		// Set the options for the chart
		setOptions() {
			this.options = JSON.parse(JSON.stringify(PieChartOptions));
			this.options.legend.display = false;
			this.options.plugins = Object.assign({}, PieChartOptions.plugins);

			this.options.tooltips.callbacks.label = (tooltipItem, data) => {
				let index = tooltipItem.index;
				let category = data.labels[index];
				let dataset = data.datasets[tooltipItem.datasetIndex];

				const value = dataset.data[index];
				const calculatedData = dataset.data.slice();

				// When calculating the tooltip percent value, remove from the total the hidden categories
				const chart = this.$refs.chart.$data._chart;
				let meta = chart.getDatasetMeta(0);

				meta.data.forEach((el, index) => {
					if(el.hidden) {
						calculatedData[index] = 0;
					}
				});

				const total = calculatedData.reduce((a, b) => { return a + b; });
				const percentage = (100 * (calculatedData[tooltipItem.index] / total)).toFixed(1);
				const label =  percentage > 0 && !isNaN(percentage) ? " - " + percentage + "%" : "";

				return category ? category + ": " + value + label : "N.A." + ": " + value + label;
			}

			// Create the html legend
			this.options.legendCallback = (chart) => {
				let legendHtml = [];
				legendHtml.push('<ul>');

				let item = chart.data.datasets[0];
				let maxLabelLenght = 0;

				item.data.forEach((d, index) => {
					const label = (chart.data.labels[index] || this.$t("Analytics.NA"));
					const bgColor = item.backgroundColor[index];
					let meta = chart.getDatasetMeta(0);

					maxLabelLenght = maxLabelLenght < label.length ? label.length : maxLabelLenght; 

					let itemClass = meta.data[index].hidden ? 'hidden' : '';

					legendHtml.push('<li title="' + label + '" class="' + itemClass + '">');
					legendHtml.push('<span id="chart-legend-box" style="background-color:' + bgColor + '"></span>');
					legendHtml.push('<span id="chart-legend-label">' + label + ' </span>');
					legendHtml.push('</li>');

				});

                this.legendSize = maxLabelLenght <= 20 ? (maxLabelLenght <= 12 ? (maxLabelLenght <= 6 ? 3 : 4) : 5) : 6;

				legendHtml.push('</ul>');
				return legendHtml.join("");
			};
		},
		// Highligth the selected slice of the pie chart
		highlightDataset(elementIndex, active) {
			this.chartData.datasets[0].backgroundColor[elementIndex] = active ? this.darkPalette[elementIndex] : this.lightPalette[elementIndex];
		},
		// Handle the click event
		handleClick(evt) {
			const chart = this.$refs.chart.$data._chart;
			let activeElement = chart.getElementAtEvent(evt);

			if (activeElement.length > 0) {
				activeElement = activeElement[0];

				if (0 == activeElement._datasetIndex) {
					this.doClick(activeElement._index);
				}
			}
		},
		// Perform the click
		doClick(index) {
			this.myselfClicked = true;
			const reset = this.selectedIndex == index;
			this.selectedIndex = reset ? null : index;

			const label = this.chartData.labels[index];
			const filter = label ? label : this.myself + "_" + this.cds.NotAssignedLabel;
			const filterName = label ? label : this.fetchOrgName(this.myself) + "_" + this.cds.NotAssignedLabel;
			if(label == this.$t('Roles.Other')) {
				return
			}
			// Avoid reloading and emitting filter_changed event if no filter has been applied
			let appliedFilter = this.cds.setFilter(this.myself, reset ? null : filter, filterName);
			if(appliedFilter) {
				this.cds.emitFilterChanges();
				this.cds.setQueryParam(this.myself, reset ? null : filter);
				this.cds.reload();
			}
		},
		// Handle the click event on the legend
		legendClickCallback(event) {
			let chart = this.$refs.chart.$data._chart;
			var target = event.target;

			while (target.nodeName !== 'LI') {
				target = target.parentElement;
			}

			let index = Array.prototype.slice.call(target.parentElement.children).indexOf(target);

			// The click on the legend box, simulate the click on the chart
			if (event.srcElement.id === 'chart-legend-box') {
				this.doClick(index);
			} 
			// Clicking on the legend label hides the corresponding chart dataset
			else if (event.srcElement.id === 'chart-legend-label') {
				chart.data.datasets.forEach((dataset, datasetindex) => {
					let meta = chart.getDatasetMeta(datasetindex);

					let item = meta.data[index];
					item.hidden = !item.hidden;
				});

				target.classList.toggle('hidden');
				chart.update();
			}
		},
		handleHover(evt) {
			const chart = this.$refs.chart.$data._chart;
			let activeElement = chart.getElementAtEvent(evt);

			if (activeElement.length > 0) {
				activeElement = activeElement[0];
				evt.target.style.cursor = activeElement._datasetIndex == 0 ? 'pointer' : 'default';

				const active = this.chartData.datasets[0].active[activeElement._index];

				if (activeElement._datasetIndex == 0) {
					activeElement._chart.update();
					activeElement._model.outerRadius = !active ? activeElement._model.outerRadius + 7 : activeElement._model.outerRadius;
					activeElement._model.innerRadius = this.chartData.datasets.length > 1 && !active ? activeElement._model.innerRadius + 7 : activeElement._model.innerRadius;
				}
			} else {
				evt.target.style.cursor = 'default';
				this.$refs.chart.$data._chart.update();
			}
		},
		// Toggle active status
		toggleActive() {
			const myselfActive = this.cds.filter[this.myself];
			let elementIndex = null;
			this.myselfClicked = false;
			
			// Check if chart is filtered by labelless orgs
			if (myselfActive && myselfActive.indexOf(this.cds.NotAssignedLabel) !== -1) {
				elementIndex = this.chartData.labels.indexOf(null);
			} else {
				elementIndex = !myselfActive ? this.selectedIndex : this.chartData.labels.indexOf(myselfActive);
			}

			const othersActive = this.cds.hasOtherValidFilters(this.myself);

			// Perform the toggle
			this.chartData.labels.forEach((label, index) => {
				this.chartData.datasets[0].active[index] = ((index == elementIndex) && myselfActive) || (label == myselfActive);
				this.highlightDataset(index, myselfActive ? this.chartData.datasets[0].active[index] : !othersActive);
			});

			this.$refs.chart.$data._chart.update();
		},
		removeFilter(event) {
			if (event.target.classList.contains("close")) {
				this.cds.removeFilter(event.target.getAttribute("filter"));
				this.cds.emitFilterChanges();
			}
		},
		selectChart(event) {
			this.cds.setFilter(this.myself, null);
			this.cds.removeFilter(this.myself);
			this.cds.emitFilterChanges();
			this.breadCrumb = this.cds.getInteractiveBreadcrumb();
			this.$emit("chartChanged", event.target.getAttribute("myself"));
		}
	}
}
</script>

<style lang="less">
#riskByOrgLevel {
	min-height: 484px;
	.empty-message.risk-org {
		height: 348px;
	}
}
</style>