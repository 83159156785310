<template>
	<b-card id="serialClickerRate">
		<b-card-title class="mb-4">
			<div class="title" :title="title">
				{{ title }}
				<font-awesome-icon id="serialClickerRateTooltip" :icon="['fas', 'info-circle']" class="mr-1"/>
				<b-tooltip class="mt-2 mr-2" triggers="hover" right target="serialClickerRateTooltip">
					<span v-html="$t('Dashboard.Tooltips.SerialClickerRateTooltipInfo')"></span>
				</b-tooltip>
			</div>
			<b-col class="filter-container">
				<span v-html="breadCrumb" @click="removeFilter"></span>
			</b-col>
		</b-card-title>
		<cg-line-chart v-if="showChart" :chart-data="chartData" :options="options" ref="chart"></cg-line-chart>
	</b-card>
</template> 

<script>
import LineChart from "@/components/charts/wrappers/LineChart";
import colors from "@/components/charts/utils/colors";

export default {
	components: {
		"cg-line-chart": LineChart
	},
	data() {
		return {
			title: this.$t("Dashboard.Charts.WeakUser"),
			inProgress: false,
			showChart: false,
			chartData: {},
			chartLabel: '',
			breadCrumb: "",
			options: {}
		};
	},
	props: {
		cds: Object,
	},
	mounted() {
		this.chartLabel = this.$t('Dashboard.Charts.Weak');

		this.cds.getBus().$on('cds_refresh_start', () => { this.inProgress = true; });
		this.cds.getBus().$on('cds_refresh_end', this.refresh);
		this.cds.getBus().$on('cds_transform_change', () => {
			if (this.cds.getTransforms().length > 0) {
				this.render();
			} else {
				this.refresh();
			}
			this.$refs.chart.$data._chart.update();
		});
		this.cds.getBus().$on("filter_changed", () => {
			// Get the breadcrumb excluding the filters by risk group
			this.breadCrumb = this.cds.getInteractiveBreadcrumbWithout(
				this.cds.getOtherFilters().filter((f) => {
					return f.filter != this.cds.Filter.risk && f.filter != this.cds.Filter.riskGroup;
				}));
		});
	},
	methods: {
		refresh() {
			this.inProgress = false;

			let data = this.cds.getData();

			this.showChart = data ? true : false;

			data = this.cds.applyTransform(data, this.cds.CdsXform.SummarizeCampaign);

			data.forEach((c) => {
				c.serial_click = c.stats[this.cds.RiskGroup.Serial].clicked;
			});

			this.create(data);
		},
		create(data) {
			this.setData(data);
			this.setOptions();
			this.render();
		},
		setData(data) {
			this.chartData = {
				labels: [],
				datasets: [
					{
						label: this.chartLabel,
						data: [],
						borderColor: colors.plain.darkRed,
						pointBorderColor: [],
						pointBackgroundColor: [],
						backgroundColor: colors.alpha.red,
						pointRadius: [],
						sent: [],
						clicked: [],
						period: []
					}
				]
			};
			data.reverse().forEach((stats) => {
				this.chartData.labels.push(stats.name);
				this.chartData.datasets[0].data.push(stats.serial_click);
				this.chartData.datasets[0].sent.push(stats.total.sent);
				this.chartData.datasets[0].clicked.push(stats.total.clicked);

				let planned_start = this.$moment.utc(stats.planned_start).local().locale(this.$i18n.locale).format("YYYY-MM-DD");
				let planned_end = this.$moment.utc(stats.planned_end).local().locale(this.$i18n.locale).format("YYYY-MM-DD");
				this.chartData.datasets[0].period.push(planned_start + " - " + planned_end);
			});
		},
		// Set the defaults colors and the status for each element of the chart
		render() {
			const filter = this.cds.Filter.campaign;
			this.chartData.datasets[0].data.forEach((value, elementIndex) => {
				const active = this.chartData.labels[elementIndex] == this.cds.filterName[filter];
				this.highlightPoint(elementIndex, active ? true : !this.cds.filter[filter]);
			});
		},
		setOptions() {
			this.options = {
				scales: {
					xAxes: [
						{
							gridLines: {
								display: false
							}
						}
					],
					yAxes: [
						{
							ticks: {
								min: 0
							},
							scaleLabel: {
								display: true
							}
						}
					]
				},
				elements: {
					point: {
						pointHoverBorderWidth: 2
					},
					line: {
						tension: 0,
						borderWidth: 2
					}
				},
				legend: {
					display: false
				},
				responsive: true,
				maintainAspectRatio: false,
				animation: {
					animateRotate: true,
					animateScale: true
				},
				tooltips: {
					position: "average",
					mode: "index",
					intersect: false,
					backgroundColor: colors.plain.white,
					titleFontColor: colors.plain.primary,
					bodyFontColor: colors.plain.primary,
					borderColor: colors.plain.grey,
					borderWidth: 0.5,
					cornerRadius: 10,
					xPadding: 15,
					yPadding: 10,
					callbacks: {
						afterBody: (tooltipItem, data) => {
							let body = [];

							let datasetIndex = tooltipItem[0].datasetIndex;
							let index = tooltipItem[0].index;

							let sent = data.datasets[datasetIndex].sent[index];
							let clicked = data.datasets[datasetIndex].clicked[index];
							let period = data.datasets[datasetIndex].period[index];
							
							body.push("");
							body.push(this.$t('Dashboard.Charts.AttacksSent') + ": " + sent);
							body.push(this.$t('Dashboard.Charts.TotalClicks') + ": " + clicked);
							body.push(this.$t('Dashboard.Charts.SuccessfulAttacks') + ": " + (100 * (clicked / sent)).toFixed(1) + "%");
							body.push(this.$t('Dashboard.Charts.CampaignPeriod') + ": " + period);

							return body;
						}
					}
				},
				hover: {
					mode: "index",
					intersect: false
				},
				plugins: {
					datalabels: {
						display: false
					}
				}
			};
		},
		// Highligth the selected segment of the bar
		highlightPoint(elementIndex, active) {
			this.chartData.datasets[0].pointBorderColor[elementIndex] = active ? colors.plain.darkRed : colors.alpha.red;
			this.chartData.datasets[0].pointBackgroundColor[elementIndex] = active ? colors.plain.darkRed : colors.alpha.red;
			this.chartData.datasets[0].pointRadius[elementIndex] = active ? 3 : 0;
		},
		removeFilter(event) {
			if (event.target.classList.contains("close")) {
				this.cds.removeFilter(event.target.getAttribute("filter"));
				this.cds.emitFilterChanges();
			}
		}
	}
};
</script>

<style lang="less">
#serialClickerRate {
	min-height: 478px;
}
</style>