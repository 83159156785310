<template>
	<b-card id="campaignStats">
		<b-card-title class="mb-4">
			<div class="title" :title="title">
				{{ title }}
			</div>
			<b-col class="filter-container">
				<span v-html="breadCrumb" @click="removeFilter"></span>
			</b-col>
			<b-dropdown v-if="!inProgress && smsEnabled" right class="pie-dropdown">
				<template v-slot:button-content>
					<label class="sr-only">{{ $t('Dashboard.Charts.StatsBy') }}</label>
					<font-awesome-icon :icon="['fas', 'ellipsis-v']" />
				</template>
				<b-dropdown-item @click="statsChanged" :myself="TEMPLATE_TYPE_GLOBAL">{{ $t('Dashboard.Charts.StatsGlobal') }}</b-dropdown-item>
				<b-dropdown-item @click="statsChanged" :myself="TEMPLATE_TYPE_SMS">{{ $t('Dashboard.Charts.StatsSms') }}</b-dropdown-item>
				<b-dropdown-item @click="statsChanged" :myself="TEMPLATE_TYPE_EMAIL">{{ $t('Dashboard.Charts.StatsEmail') }}</b-dropdown-item>
			</b-dropdown>
			<b-spinner class="spinner" variant="primary" :label="$t('General.Loading')" v-if="inProgress"></b-spinner>
		</b-card-title>
		<b-row class="text-center">
			<b-col lg="12">
				<b-list-group horizontal>
					<b-list-group-item>
						<h3>
							<animated-counter :to="stats.clickRate || 0" :precision="1"></animated-counter>% <br v-if="reportingEnabled">
							(<animated-counter :to="stats.clickCount || 0" :precision="1"></animated-counter>)
						</h3>
						<span>{{ $t('Dashboard.Charts.SuccessfulAttacks') }}&nbsp;</span>
						<font-awesome-icon id="successfulAttacksTooltip" :icon="['fas', 'info-circle']" class="mr-1"/>
						<b-tooltip class="mt-2 mr-2" triggers="hover" right target="successfulAttacksTooltip">
							<span v-html="$t('Dashboard.Tooltips.SuccessfulAttacksInfo')"></span>
						</b-tooltip>
					</b-list-group-item>
					<b-list-group-item  v-if="reportingEnabled">
						<h3>
							<animated-counter :to="stats.reportedAttacks || 0" :precision="1"></animated-counter>%
						</h3>
						<span>{{ $t('Dashboard.Charts.ReportedAttacks') }}&nbsp;</span>
						<font-awesome-icon id="reportedAttacksTooltip" :icon="['fas', 'info-circle']" class="mr-1"/>
						<b-tooltip class="mt-2 mr-2" triggers="hover" right target="reportedAttacksTooltip">
							<span v-html="$t('Dashboard.Tooltips.ReportedAttacksInfo')"></span>
						</b-tooltip>
					</b-list-group-item>
					<b-list-group-item>
						<h3>
							<animated-counter :to="stats.targetUsers || 0"></animated-counter>
						</h3>
						<span>{{ $t('Dashboard.Charts.TargetUsers') }}&nbsp;</span>
						<font-awesome-icon id="targetUsersTooltip" :icon="['fas', 'info-circle']" class="mr-1"/>
						<b-tooltip class="mt-2 mr-2" triggers="hover" right target="targetUsersTooltip">
							<span v-html="$t('Dashboard.Tooltips.TargetUsersInfo')"></span>
						</b-tooltip>
					</b-list-group-item>
					<b-list-group-item>
						<h3>
							<animated-counter :to="stats.totalAttackSent || 0"></animated-counter>
						</h3>
						<span>{{ $t('Dashboard.Charts.TotalAttacksSent') }}&nbsp;</span>
						<font-awesome-icon id="totalAttacksSentTooltip" :icon="['fas', 'info-circle']" class="mr-1"/>
						<b-tooltip class="mt-2 mr-2" triggers="hover" right target="totalAttacksSentTooltip">
							<span v-html="$t('Dashboard.Tooltips.TotalAttacksSentInfo')"></span>
						</b-tooltip>
					</b-list-group-item>
				</b-list-group>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
import CompanyService from "@/services/company.service";
import AnimatedCounter from "@/components/AnimatedCounter";
import { TemplateType } from '../../../../../server/common/constants';

export default {
	components: {
		AnimatedCounter
	},
	data: function () {
		return {
			title: this.$t('Dashboard.Charts.GlobalStats'),
			stats: {
				clickCount: 0,
				clickRate: 0,
				reportedAttacks: 0,
				targetUsers: 0,
				totalAttackSent: 0
			},
			datasets: [],
			target_stats: {},
			global_stats: {},
			breadCrumb: "",
			inProgress: false,
			reportingEnabled: false,
			smsEnabled: false,
			myself: "",
			type: null
		};
	},
	props: {
		cds: Object
	},
	async mounted() {
		this.cds.getBus().$on('cds_refresh_start', () => { 
			this.inProgress = true;
			this.$emit('loading', true);
		});
		this.cds.getBus().$on('cds_refresh_end', this.refresh_ui);
		this.cds.getBus().$on('cds_transform_change', this.refresh_ui);
		this.cds.getBus().$on('filter_changed', () => { this.breadCrumb = this.cds.getInteractiveBreadcrumb(); });

		this.reportingEnabled = await this.cds.getReportEnabled();

		this.$on("statsChanged", (myself) => {
			this.myself = myself;
			this.updateStats();
		});
	},
	methods: {
		updateStats() {
			this.title = this.$t('Dashboard.Charts.Stats' + this.myself.charAt(0).toUpperCase() + this.myself.slice(1));

			this.type = this.myself == this.TEMPLATE_TYPE_SMS? TemplateType.SMS : (this.myself == this.TEMPLATE_TYPE_EMAIL? TemplateType.Email : TemplateType.Undefined);

			this.stats.clickCount = this.datasets[this.myself].clicked;
			this.stats.clickRate = 100 * this.datasets[this.myself].clicked / this.datasets[this.myself].sent;
			this.stats.reportedAttacks = 100 * this.datasets[this.myself].reported / this.datasets[this.myself].sent;
			this.stats.totalAttackSent = this.datasets[this.myself].sent;

			this.assignAttackedTarget();
		},
		async fetchData() {
			const filters = this.cds.getFiltersFor();
			const queryParams = filters ? filters : {};
			queryParams.ctx = this.cds.ctx;

			let targetStats = this.cds.companyId ? await CompanyService.getNumAttackedTargets(this.cds.companyId, queryParams) : {};
			this.target_stats = targetStats.data;

			this.assignAttackedTarget();

			let globalStats = this.cds.companyId ? await CompanyService.getGlobalStats(this.cds.companyId, queryParams) : {};
			this.global_stats = globalStats.data;
			
			let total = {};
			if(this.global_stats && this.global_stats.length > 1) {
				total = Object.keys(this.global_stats[0]).reduce((a, k) => {
					a[k] = this.global_stats[0][k] + this.global_stats[1][k];
					return a;
				}, {});
			} else {
				total = this.global_stats[0];
			}

			this.datasets[this.TEMPLATE_TYPE_EMAIL] = this.global_stats.filter((t) => t.type === TemplateType.Email)[0] || {};
			this.datasets[this.TEMPLATE_TYPE_SMS] = this.global_stats.filter((t) => t.type === TemplateType.SMS)[0] || {};
			this.datasets[this.TEMPLATE_TYPE_GLOBAL] = total || {};
		},
		assignAttackedTarget() {
			if(this.type) {
				let item = this.global_stats.filter(elem => { return elem.type == this.type; });
				this.stats.targetUsers = item && item[0]? item[0].targets : 0;
			} else {
				this.stats.targetUsers = this.target_stats? this.target_stats.reduce(function(prev, cur) { return prev + cur.targets; }, 0) : {};
			}
		},
		async refresh_ui() {
			this.breadCrumb = this.cds.getInteractiveBreadcrumb();

			try {
				await this.fetchData();
				this.updateStats();
			} catch(error) {
				console.error("CampaignStats - missing permission to access dashboard", error);
				if(error.response.status == 401) {
					this.$emit('cgunauthorized');
				}
			} finally {
				this.inProgress = false;
				this.$emit('loading', false);
				this.$forceUpdate();
			}
		},
		removeFilter(event) {
			if (event.target.classList.contains("close")) {
				this.cds.removeFilter(event.target.getAttribute("filter"));
				this.cds.emitFilterChanges();
			}
		},
		statsChanged(event) {
			this.$emit("statsChanged", event.target.getAttribute("myself"));
		}
	},
	async created() {
		this.TEMPLATE_TYPE_SMS = "sms";
		this.TEMPLATE_TYPE_GLOBAL = "global";
		this.TEMPLATE_TYPE_EMAIL = "email";

		this.myself = this.TEMPLATE_TYPE_GLOBAL;

		let company = await CompanyService.getCompany(localStorage.getItem("cg-company"));
		this.smsEnabled = company? !!company.data.sms_enabled : false;
	}
};
</script>

<style lang="less">
#campaignStats {
	min-height: 247px;
	.list-group {
		height: 100%;
		.list-group-item {
			width: 100%;
			padding: 20px 4px;
			border: none;
			cursor: default;
		}
		.list-group-item:last-child {
			border-radius: 0;
		}
	}
	.last-group .list-group-item:last-child {
		border-right: none;
	}
	.card-title {
		.filter-container {
			padding-right: 15px !important;
		}
		.alert-drop-down {
			margin-bottom: 5px;
		}
		.bc-dropdown {
			cursor: pointer;
			font-size: 1rem;
			.dropdown-toggle::after {
				display: none;
			}
			.btn-secondary {
				color: @cg-primary-dark-text-color;
				background-color: transparent;
				border: none;
			}
		}
	}
}

// Small devices (landscape phones, 576px and up)
@media only screen and (max-width: 575px) {
}

// Medium devices (tablets, 768px and up)
@media only screen and (max-width: 767px) {
}

// Large devices (desktops, 992px and up)
@media only screen and (max-width: 991px) {
	#campaignStats {
		h3 {
			font-size: 1.75rem !important;
		}
	}
}

// Extra large devices (large desktops, 1200px and up)
@media only screen and (max-width: 1199px) {
}

@media only screen and (max-width: 1450px) {
	#campaignStats {
		h3 {
			font-size: 1.45rem;
		}
	}
}
</style>