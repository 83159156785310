<template>
    <b-card id="reliabilityScore">
        <b-card-title class="mb-4">
            <div class="title" :title="title">
                {{ title }}
                <font-awesome-icon id="reliabilityScoreTooltip" :icon="['fas', 'info-circle']" class="mr-1"/>
                <b-tooltip class="mt-2 mr-2" triggers="hover" right target="reliabilityScoreTooltip">
                    <span v-html="$t('Dashboard.Tooltips.' + (!this.isNormalized ? 'ReliabilityScoreTooltipInfo' : 'NormalizedReliabilityScoreTooltipInfo'))"></span>
                </b-tooltip>
            </div>
            <b-col class="filter-container">
                <span v-html="breadCrumb" @click="removeFilter"></span>
            </b-col>
            <b-dropdown v-if="!inProgress" right class="pie-dropdown">
                <template v-slot:button-content>
                    <label class="sr-only">{{ $t('Dashboard.Charts.ReliabilityScore') }}</label>
                    <font-awesome-icon :icon="['fas', 'ellipsis-v']" />
                </template>
                <b-dropdown-item @click="handleSwitch(false)">{{ $t("Dashboard.Charts.ReliabilityScore") }}</b-dropdown-item>
                <b-dropdown-item @click="handleSwitch(true)">{{ $t('Dashboard.Charts.ReliabilityScoreNormalized') }}</b-dropdown-item>
            </b-dropdown>
        </b-card-title>
        <cg-bar-chart v-if="showChart" :chart-data="chartData" :options="options" ref="chart"></cg-bar-chart>
    </b-card>
</template>

<script>
import BarChart from "@/components/charts/wrappers/BarChart";
import colors from "@/components/charts/utils/colors";
import CompanyService from "@/services/company.service";
import * as pattern from 'patternomaly';

export default {
    components: {
        "cg-bar-chart": BarChart
    },
    data() {
        return {
            title: this.$t("Dashboard.Charts.ReliabilityScore"),
            inProgress: false,
            showChart: false,
            chartData: {},
            options: {},
            breadCrumb: "",
            isNormalized: false
        };
    },
    props: {
        cds: Object,
    },
    mounted() {
        this.setOptions();
        this.init();

        this.cds.getBus().$on("filter_changed", () => {
            this.breadCrumb = this.cds.getInteractiveBreadcrumb();
            this.refresh();
        });
    },
    methods: {
        async init() {
            let data = await this.getData();
            if (data && Object.keys(data).length > 0) {
                this.render(data);
            }
        },
        async refresh() {
            let data = await this.getData();

            if (data) this.render(data);
        },
        // Retrieve the clicker rate
        async getData() {
            this.inProgress = true;
			
            const filters = this.cds.getFiltersFor(this.myself);
            let queryParams = filters ? filters : {};

            if (queryParams.campaign) {
                delete queryParams.campaign;
            }
            queryParams.ctx = this.cds.ctx;

            let result = this.cds.companyId ? await CompanyService.getReliabilityScore(this.cds.companyId, queryParams) : {};
            this.inProgress = false;
            this.showChart = result.data ? true : false;
            return result.data;
        },
        render(data) {
            this.setData(data);
            this.updateReliability(data);
            this.paintChart();
        },
        paintChart() {
            const filter = this.cds.Filter.campaign;
            this.chartData.datasets[0].data.forEach((value, elementIndex) => {
                const active = this.chartData.labels[elementIndex] == this.cds.filterName[filter];
                this.highlightBar(elementIndex, active ? true : !this.cds.filter[filter]);
            });
        },
        setData(data) {
            this.chartData = {
                labels: [],
                datasets: [
                    {
                        label: this.$t("Dashboard.Charts.Reliability"),
                        data: [],
                        sent: [],
                        rel: [],
                        rel_norm: [],
                        clicked: [],
                        period: [],
                        backgroundColor: [],
                        borderColor: [],
                        borderWidth: 1,
                        maxBarThickness: 150
                    }
                ]
            };
            data.reverse().forEach((c) => {
                this.chartData.labels.push(c.name);
                this.chartData.datasets[0].rel.push(c.rel.toFixed(1));
                this.chartData.datasets[0].rel_norm.push(c.rel_norm.toFixed(1));
                let value = this.isNormalized? c.rel_norm.toFixed(1) : c.rel.toFixed(1);
                this.chartData.datasets[0].data.push(value);
                this.chartData.datasets[0].sent.push(c.sent);
                this.chartData.datasets[0].clicked.push(c.clicked);
                let planned_start = this.$moment.utc(c.planned_start).local().locale(this.$i18n.locale).format("YYYY-MM-DD");
                let planned_end = this.$moment.utc(c.planned_end).local().locale(this.$i18n.locale).format("YYYY-MM-DD");
                this.chartData.datasets[0].period.push(planned_start + " - " + planned_end);
                let bgColor = this.isNormalized? pattern.draw('diagonal', colors.alpha.blue) : colors.alpha.blue;
                this.chartData.datasets[0].backgroundColor.push(bgColor);
            });
        },
        setOptions() {
            this.options = {
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                display: false
                            }
                        }
                    ],
                    yAxes: [
                        {
                            ticks: {
                                min: 0,
                                callback: function (value) {
                                    return value;
                                }
                            },
                            scaleLabel: {
                                display: true
                            }
                        }
                    ]
                },
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                    animateRotate: true,
                    animateScale: true
                },
                legend: {
                    display: false
                },
                tooltips: {
                    backgroundColor: colors.plain.white,
                    titleFontColor: colors.plain.primary,
                    bodyFontColor: colors.plain.primary,
                    borderColor: colors.plain.grey,
                    borderWidth: 0.5,
                    cornerRadius: 10,
                    position: 'custom',
                    xPadding: 15,
                    yPadding: 10,
                    callbacks: {
                        title: (tooltipItem) => {
                            return this.$t('Dashboard.Charts.ReliabilityForCampaign', { param: tooltipItem[0].label });
                        },
                        label: function (tooltipItem, data) {
                            let category = data.datasets[tooltipItem.datasetIndex].label;
                            return category + " " + parseFloat(tooltipItem.value).toFixed(1);
                        },
                        afterBody: (tooltipItem, data) => {
                            let body = [];

                            let datasetIndex = tooltipItem[0].datasetIndex;
                            let index = tooltipItem[0].index;

                            let sent = data.datasets[datasetIndex].sent[index];
                            let clicked = data.datasets[datasetIndex].clicked[index];
                            let period = data.datasets[datasetIndex].period[index];
                            
                            body.push("");
                            body.push(this.$t('Dashboard.Charts.AttacksSent') + ": " + sent);
                            body.push(this.$t('Dashboard.Charts.TotalClicks') + ": " + clicked);
                            body.push(this.$t('Dashboard.Charts.SuccessfulAttacks') + ": " + (100 * (clicked / sent)).toFixed(1) + "%");
                            body.push(this.$t('Dashboard.Charts.CampaignPeriod') + ": " + period);
                            
							return body;
                        }
                    }
                },
                plugins: {
                    datalabels: {
                        formatter: function (_value) {
                            return _value > 0 ? parseFloat(_value).toFixed(1) : "";
                        },
                        clamp: true,
                        clip: true
                    }
                }
            };
        },
        updateReliability(data) {
            this.chartData.labels.forEach((label, index) => {
                const idx = data.length == 1 ? 0 : index;
                const sequence = data[idx].seq_no;
                const cName = 'C-' + (sequence < 10 ? '0' : '') + sequence;
                if (label === cName) {
                    this.chartData.datasets[0].data[index] = this.isNormalized? data[index].rel_norm.toFixed(1) : data[index].rel.toFixed(1);
                }
            });
        },
        // Highligth the selected segment of the bar
        highlightBar(elementIndex, active) {
            if (active) {
                this.chartData.datasets[0].backgroundColor[elementIndex] = this.isNormalized? pattern.draw('diagonal', colors.alpha.blue) : colors.alpha.blue;
                this.chartData.datasets[0].borderColor[elementIndex] = colors.plain.blue;
            } else {
                this.chartData.datasets[0].backgroundColor[elementIndex] = this.isNormalized? pattern.draw('diagonal', colors.alpha.lightBlue) : colors.alpha.lightBlue;
                this.chartData.datasets[0].borderColor[elementIndex] = colors.alpha.lightBlue;
            }
        },
        removeFilter(event) {
            if (event.target.classList.contains("close")) {
                this.cds.removeFilter(event.target.getAttribute("filter"));
                this.cds.emitFilterChanges();
            }
        },
        handleSwitch(value) {
            if(this.isNormalized == value) {
                // Prevent chart reload if the option is the very same already displayed
                return;
            }

            this.isNormalized = value;
            this.title = this.$t('Dashboard.Charts.ReliabilityScore' + (this.isNormalized? 'Normalized' : ''));
            this.chartData.datasets[0].data = this.isNormalized? this.chartData.datasets[0].rel_norm : this.chartData.datasets[0].rel;
            this.paintChart();
            this.$refs.chart.renderChart(this.chartData, this.options);
        }
    }
};
</script>

<style lang="less">
#reliabilityScore {
    min-height: 478px;
}
</style>