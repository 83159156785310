<template>
	<div id="reportDownloaderContainer">

		<b-modal id="downloadDataModal" size="lg" ref="downloadDataModal" :title="$t('Dashboard.ExportData')" hide-footer body-class="px-0 py-0 modal-body-full-width template">
			<div class="mx-4 my-4">

				<b-form-group label-cols-lg="12" :label="$t('Dashboard.SelectCampaign')" label-size="lg" label-class="pt-0">
					<!-- Campaign selector -->
					<b-form-group class="form-align-self-center" label-cols-sm="6" label-for="campaignSelect" :label="$t('Dashboard.AvailableCampaigns')"
						label-align-sm="right">
						<b-form-select id="campaignSelect" size="sm" v-model="campaignId" :options="campaignOptions">
						</b-form-select>
					</b-form-group>

				</b-form-group>

				<div v-if="!isExportAvailable && !isReportAvailable" class="mb-4">
					<span class="font-weight-bold">{{ $t('Dashboard.UnableToDownloadReportInfo') }}</span>
				</div>

				<!-- Report -->
				<div v-if="$checkPermission('create-company-report')">	
					<div class="separator mb-4"></div>

					<b-form-group label-cols-lg="12" :label="$t('Dashboard.CreateReport')" label-size="lg" label-class="pt-0">
						<b-form-group class="form-align-self-center" label-cols-sm="6" label-sr-only label-for="globalReport" 
							:label="$t('Dashboard.GlobalReport')" label-align-sm="right">
							<b-button variant="primary" id="globalReport" class="btn-action w-100" :href="globalReport" :disabled="!isReportAvailable">
								{{ $t('Dashboard.GlobalReport') }}</b-button>
						</b-form-group>

						<b-form-group class="form-align-self-center" label-cols-sm="6" label-sr-only label-for="singleReport" 
							:label="$t('Dashboard.SingleReport')" label-align-sm="right">
							<b-button variant="primary" id="singleReport" class="btn-action w-100" :href="singleReport" :disabled="!campaignId">
								{{ downloadSingleCampaignReport }}</b-button>
						</b-form-group>
					</b-form-group>
				</div>	

				<!-- Export -->
				<div v-if="$checkPermission('export-company-data,view-target-risk')">
					<div class="separator mb-4"></div>

					<b-form-group label-cols-lg="12" :label="$t('Dashboard.ExportData')" label-size="lg" label-class="pt-0">
						<b-form-group class="form-align-self-center" label-cols-sm="6" label-sr-only label-for="globalExport" 
							:label="$t('Dashboard.GlobalExport')" label-align-sm="right">
							<b-button variant="primary" id="globalExport" class="btn-action w-100" :href="globalExport" :disabled="!isExportAvailable">
								{{ $t('Dashboard.GlobalExport') }}</b-button>
						</b-form-group>

						<b-form-group class="form-align-self-center" label-cols-sm="6" label-sr-only label-for="singleExport" 
							:label="$t('Dashboard.SingleExport')" label-align-sm="right">
							<b-button variant="primary" id="singleExport" class="btn-action w-100" :href="singleExport" :disabled="!campaignId">
								{{ downloadSingleCampaignExport }}</b-button>
						</b-form-group>
					</b-form-group>
				</div>
			</div>
		</b-modal>

		<b-button id="downloadDataModal" variant="primary" size="sm" class="float-right mb-3" @click="$bvModal.show('downloadDataModal')">
			<font-awesome-icon class="vertical-align-middle mr-2" :icon="['fas', 'file-archive']" />{{ $t("Dashboard.ExportData") }}
		</b-button>
	</div>
</template>
<script>
import CompanyService from '../../services/company.service';
import syspropService from "../../services/sysprop.service.js";
import { Campaign } from '../../../../server/common/constants';

export default {
	data() {
		return {
			language: null,
			campaignId: null,
			campaignOptions: [],
			onboardingCampaigns: 1,
			isReportAvailable: false,
			isExportAvailable: false,
			reportConfig: { slideNumbers: true },
			globalExport: ''
		};
	},
	props: {
		companyId: String
	},
	computed: {
		globalReport() {
			return `api/company/${this.companyId}/report?lang=${this.language}&base=${this.onboardingCampaigns}&slideNumbers=${this.reportConfig.slideNumbers}`;
		},
		singleReport() {
			return `api/campaign/${this.campaignId}/report?lang=${this.language}`;
		},
		singleExport() {
			return `api/company/${this.companyId}/export?campaignId=${this.campaignId}`;
		},
		downloadSingleCampaignReport() {
			return this.$t('Dashboard.SingleReport', { param: this.campaignName });
		},
		downloadSingleCampaignExport() {
			return this.$t('Dashboard.SingleExport', { param: this.campaignName });
		},
		campaignName() {
			return this.campaignId? this.campaignOptions.filter((c) => this.campaignId == c.value).map((c) => c.text) : '';
		}
	},
	async mounted() {
		await this.retrieveLinks();

		this.globalExport = `api/company/${this.companyId}/export`;
	},
	methods: {
		async retrieveLinks() {
			let config = await syspropService.getConfig();

			this.reportConfig = config.data.defaults.report;

			var option = { status: Campaign.Status.Archived };
			
			let companyRes = this.companyId ? await CompanyService.getCompany(this.companyId) : {};
			this.language = this.$account.language || companyRes.data && companyRes.data.default_language || this.reportConfig.lang || this.$i18n.locale;

			this.onboardingCampaigns = companyRes.data && companyRes.data.n_onboarding_campaigns || this.reportConfig.base;

			// Populate Campaign options array
			let result = this.companyId ? await CompanyService.getCampaignList(this.companyId, option, Campaign.Type.Ordinary) : {};
			this.campaignOptions = result.data.map((i) => {
				let period = `${this.$moment.utc(i.planned_start).local().locale(this.$i18n.locale).format("YYYY-MM-DD")} 
							- ${this.$moment.utc(i.planned_end).local().locale(this.$i18n.locale).format("YYYY-MM-DD")}`; 
				return { 'value': i.campaign_id, 'text':`${i.name} ${period}` }})
				.sort((a, b) => { return ('' + a.text).localeCompare('' + b.text); });
			this.campaignOptions.unshift({ value: null, text: this.$t('General.SelectOption') });

			this.isReportAvailable = result.data && result.data.length > this.onboardingCampaigns;
			this.isExportAvailable = result.data && result.data.length >= 1;
		}
	}
};
</script>

<style scoped lang="less">
// Small devices (landscape phones, 576px and up)
@media only screen and (max-width: 575px) {
	#downloadDataModal {
		width: 100%;
	}
}
</style>