<template>
	<b-container fluid id="dashboard">
		<cg-loader :display="loading"></cg-loader>
		<cg-localized-alert :alertVariant="alertVariant" :alertParams="alertParams" ref="cgLocAlert" :alertMessage="alertMessage"></cg-localized-alert>

		<b-modal id="missing-permission" content-class="shadow" size='md' ok-only bodyClass="text-center" 
			:centered='true' :title="$t('Dashboard.MissingPermissionModalTitle')">
			<p class="my-2 text-left" v-html="$t('Dashboard.MissingPermissionModalText')"></p>
		</b-modal>
		
		<div class="page-content">
			<b-row v-if="$checkPermission('view-campaign-summary')">
				<b-col lg="12">
					<cg-notification class="mb-3" v-if="$checkPermission('view-campaign,view-template')"></cg-notification>
				</b-col>
				
				<b-col :cols="$checkPermission('create-company-report') || $checkPermission('export-company-data') ? 8 : 12">
					<b-row class="font-14 mb-3" align-v="center">
						<b-col cols="auto" class="pr-0">
							<last-update :separator="filteredOrgLevel && filteredOrgLevel.length > 0"></last-update>
						</b-col>
						<b-col class="pl-0">
							<org-filter :orgs="filteredOrgLevel"></org-filter>
						</b-col>
					</b-row>
				</b-col>
				
				<b-col cols="4" v-if="$checkPermission('create-company-report') || $checkPermission('export-company-data')">
					<b-row align-h="end">
						<b-col cols="auto">
							<report-downloader :companyId="cds.companyId" class="mb-3"></report-downloader>
						</b-col>
					</b-row>
				</b-col>

				<b-col xl="5" lg="6" md="12" class="mb-4">
					<campaign-stats :cds="cds" @loading="setLoader" @cgunauthorized="showModal"></campaign-stats>
				</b-col>

				<b-col xl="3" lg="6" class="mb-4">
					<campaign-progress :cds="cds"></campaign-progress>
				</b-col>

				<b-col xl="4" class="mb-4">
					<campaign-result :cds="cds"></campaign-result>
				</b-col>

				<b-col lg="12" class="mb-4">
					<cyber-chain-strength :cds="cds"></cyber-chain-strength>
				</b-col>

				<b-col lg="6" class="mb-4">
					<weak-user-rate :cds="cds"></weak-user-rate>
				</b-col>

				<b-col lg="6" class="mb-4">
					<reliability-score :cds="cds"></reliability-score>
				</b-col>

				<b-col lg="6" class="mb-4">
					<risk-by-location :cds="cds"></risk-by-location>
				</b-col>

				<b-col lg="6" class="mb-4">
					<risk-by-org-level :cds="cds" :maxSliceSize="maxSliceSize"></risk-by-org-level>
				</b-col>
			</b-row>
		</div>
	</b-container>
</template>

<script>
import Vue from 'vue';

import ReportDownloader from "@/components/dashboard/ReportDownloader";
import CampaignProgress from "@/components/dashboard/cards/CampaignProgress";
import CampaignStats from "@/components/dashboard/cards/CampaignStats";
import CampaignResult from "@/components/dashboard/cards/CampaignResult";
import CyberChainStrength from "@/components/dashboard/cards/CyberChainStrength";
import WeakUserRate from "@/components/dashboard/cards/WeakUserRate";
import ReliabilityScore from "@/components/dashboard/cards/ReliabilityScore";
import RiskByOrgLevel from "@/components/dashboard/cards/RiskByOrgLevel";
import RiskByLocation from "@/components/dashboard/cards/RiskByLocation";
import LastUpdate from "@/components/LastUpdate";
import createCampaignDashboardDataSource from "@/services/dashboard.js";
import syspropService from "@/services/sysprop.service.js";
import OrgFilter from '@/components/OrgFilter.vue';

export default {
	components: {
		ReportDownloader,
		CampaignProgress,
		CampaignStats,
		CampaignResult,
		CyberChainStrength,
		WeakUserRate,
		ReliabilityScore,
		RiskByOrgLevel,
		RiskByLocation,
		LastUpdate,
		OrgFilter
	},
	data: function () {
		return {
			cds: this.$checkPermission('view-campaign-summary') ? createCampaignDashboardDataSource({ log: console, bus: new Vue() }, localStorage.getItem("cg-company"), this.$account) : null,
			alertMessage: '',
			alertParams: {},
			alertVariant: 'success',
			loading: false,
			maxSliceSize: 0,
			filteredOrgLevel: []
		};
	},
	async mounted() {
		if (this.cds) {
			let companyId = ''+this.$account.current_company;
			if(this.$account.filtered_orgs && this.$account.filtered_orgs[companyId]) {
				Object.values(this.$account.filtered_orgs[companyId]).forEach((org) => this.filteredOrgLevel.push(org));
			}
			await this.cds.refresh();
		}

		// if user dont have permission to see dashboard or analytics try to display campaigns tab
		if(!this.$checkPermission('view-campaign-summary')) {
			this.$router.push("campaigns");
		}
	},
	async created() {
		let config = await syspropService.getConfig();
		this.maxSliceSize = config.data.defaults.dashboard.maxSliceSize;
	},
	methods: {
		setLoader(status) {
			this.loading = status;
		},
		showModal() {
			this.$bvModal.show("missing-permission");
		},
		showAlert(event) {
			this.alertVariant = event.type;
			this.alertMessage = event.message;
			this.$refs.cgLocAlert.showAlert();
		}
	}
};
</script>

<style lang="less">
.tooltip-inner {
	min-width: 100px;
	background-color: white;
	color: #2c3e50;
	font-size: 12px;
	border: 1px solid #2c3e50;
	font-family: "Avenir", Helvetica, Arial, sans-serif;
}
</style>