<template>
	<b-card id="campaignResult">
		<b-card-title class="mb-4">
			<div class="title" :title="title">
				{{ title }}
			</div>
			<b-col class="filter-container">
				<span v-html="breadCrumb" @click="removeFilter"></span>
			</b-col>
		</b-card-title>
		<cg-doughnut-chart
			v-if="showChart && !isEmpty"
			:chart-data="chartData"
			:options="options"
			:height="150"
			ref="chart"
		></cg-doughnut-chart>
		<div v-if="isEmpty" class="empty-message campaign my-2">{{ $t('Analytics.Table.Empty') }}</div>
	</b-card>
</template>

<script>
import DoughnutChart from "@/components/charts/wrappers/DoughnutChart";
import colors from "@/components/charts/utils/colors";

export default {
	components: {
		"cg-doughnut-chart": DoughnutChart
	},
	data() {
		return {
			title: this.$t("Dashboard.Charts.CampaignResult"),
			inProgress: false,
			showChart: false,
			chartData: {},
			options: {},
			breadCrumb: "",
			isEmpty: false,
			reportingEnabled: false
		};
	},
	props: {
		cds: Object,
	},
	async mounted() {
		this.cds.getBus().$on('cds_refresh_start', () => { this.inProgress = true; });
		this.cds.getBus().$on('cds_refresh_end', this.refresh_ui);
		this.cds.getBus().$on('cds_transform_change', this.refresh_ui);
		this.cds.getBus().$on("filter_changed", () => { this.breadCrumb = this.cds.getInteractiveBreadcrumb(); });

		this.reportingEnabled = await this.cds.getReportEnabled();

		if(this.reportingEnabled) {
			this.chartLabels = [this.$t('Dashboard.Charts.Ignored'), this.$t('Dashboard.Charts.Clicked'), this.$t('Dashboard.Charts.Reported')];
		} else {
			this.chartLabels = [this.$t('Dashboard.Charts.Ignored'), this.$t('Dashboard.Charts.Clicked')];
		}
	},
	methods: {
		refresh_ui() {
			this.inProgress = false;
			this.breadCrumb = this.cds.getInteractiveBreadcrumb();

			let data = this.cds.getData();

			const last = data.length > 0 ? `<div title="${data[0].name}" role="alert" aria-live="polite" aria-atomic="true" class="alert bc-small alert-info">${data[0].name}</div>` : "";
			const full = this.cds.getInteractiveBreadcrumb();

			if (!this.cds.filter.campaign && !this.cds.filter.risk_group) {
				this.breadCrumb = Object.keys(this.cds.getQueryParams()).length == 0 ? last : full + last;

				if (this.cds.getTransforms().length == 0) {
					data.splice(1, data.length - 1);
				}
			}

			data = this.cds.applyTransform(data, this.cds.CdsXform.AggregateCampaigns);
			data = this.cds.applyTransform(data, this.cds.CdsXform.SummarizeCampaign);
			data = data[0];

			this.showChart = true;
			this.create([{ n_sent: data.total.sent, n_clicked: data.total.clicked, n_reported: data.total.reported }]);
			this.$forceUpdate();
		},
		create(data) {
			this.setData(data);
			this.setOptions();
		},
		setData(data) {
			let ignored, clicked, reported = 0;
			let dataset = [];

			this.isEmpty = data[0].n_sent || data[0].n_clicked ? false : true;

			ignored = data[0].n_sent - data[0].n_clicked;
			clicked = data[0].n_clicked;

			if(this.reportingEnabled) {
				this.isEmpty = data[0].n_reported ? false : this.isEmpty;
				ignored = ignored - data[0].n_reported;
				reported = data[0].n_reported;
				dataset.push(ignored, clicked, reported);
			} else {
				dataset.push(ignored, clicked);
			}

			this.chartData = {
				labels: this.chartLabels,
				datasets: [
					{
						backgroundColor: [colors.alpha.green, colors.alpha.red, colors.alpha.blue],
						borderColor: [colors.plain.green, colors.plain.red, colors.plain.blue],
						borderWidth: 1,
						data: dataset
					}
				]
			};
		},
		setOptions() {
			this.options = {
				layout: {
					padding: {
						top: 20
					}
				},
				legend: {
					display: true,
					labels: {
						fontColor: colors.plain.primary,
						fontSize: 16,
						padding: 20
					},
					position: "left"
				},
				tooltips: {
					backgroundColor: colors.plain.white,
					titleFontColor: colors.plain.primary,
					bodyFontColor: colors.plain.primary,
					borderColor: colors.plain.grey,
					borderWidth: 0.5,
					cornerRadius: 10,
					xPadding: 15,
					yPadding: 10
				},
				responsive: true,
				maintainAspectRatio: false,
				animation: {
					animateRotate: true,
					animateScale: true
				},
				cutoutPercentage: 50,
				circumference: Math.PI,
				rotation: -Math.PI,
				plugins: {
					datalabels: {
						formatter: function (_value, context) {
							const data = context.dataset.data;
							const total = data.reduce((a, b) => {
								return a + b;
							});
							const percentage = (100 * (_value / total)).toFixed(1);
							return percentage > 0 ? percentage + "%" : "";
						},
						clamp: true
					}
				}
			};
		},
		removeFilter(event) {
			if (event.target.classList.contains("close")) {
				this.cds.removeFilter(event.target.getAttribute("filter"));
				this.cds.emitFilterChanges();
			}
		}
	}
};
</script>

<style lang="less">
#campaignResult {
	min-height: 247px;
	.card-title {
		span {
			.bc-small {
				padding: 2px 10px 2px 5px !important;
			}
		}
	}
	.empty-message.campaign {
		height: 140px;
	}
}
</style>