<template>
	<b-card id="campaignProgress">
		<b-card-title class="mb-4">
			<div class="title" :title="title">
				{{ title }}
				<font-awesome-icon id="attacksSentTooltip" :icon="['fas', 'info-circle']" class="mr-1"/>
				<b-tooltip class="mt-2 mr-2" triggers="hover" right target="attacksSentTooltip">
					<span v-html="$t('Dashboard.Tooltips.AttackSentInfo')"></span>
				</b-tooltip>
			</div>
			<b-col class="filter-container">
				<span v-html="breadCrumb" @click="removeFilter"></span>
			</b-col>
		</b-card-title>
		<h3 class="mb-0">
			<animated-counter :to="sent"></animated-counter>&nbsp;/
			<animated-counter :to="targets"></animated-counter>
			<span style="font-size: 1rem !important;"></span>
		</h3>

		<cg-horizontal-bar-chart
			v-if="showChart"
			:chart-data="chartData"
			:options="options"
			:height="100"
			ref="chart"
		></cg-horizontal-bar-chart>
	</b-card>
</template>

<script>
import HorizontalBarChart from "@/components/charts/wrappers/HorizontalBarChart";
import colors from "@/components/charts/utils/colors";
import AnimatedCounter from "@/components/AnimatedCounter";

export default {
	components: {
		"cg-horizontal-bar-chart": HorizontalBarChart,
		AnimatedCounter
	},
	data() {
		return {
			title: this.$t('Dashboard.Charts.AttacksSent'),
			inProgress: false,
			showChart: false,
			chartData: {},
			options: {},
			breadCrumb: "",
			sent: 0,
			targets: 0
		};
	},
	props: {
		cds: Object,
	},
	mounted() {
		this.cds.getBus().$on('cds_refresh_start', () => { this.inProgress = true; });
		this.cds.getBus().$on('cds_refresh_end', this.refresh_ui);
		this.cds.getBus().$on('cds_transform_change', this.refresh_ui);
		this.cds.getBus().$on("filter_changed", () => { this.breadCrumb = this.cds.getInteractiveBreadcrumb(); });
	},
	methods: {
		refresh_ui() {
			this.inProgress = false;
			this.breadCrumb = this.cds.getInteractiveBreadcrumb();

			let data = this.cds.getData();

			const last = data.length > 0 ? `<div title="${data[0].name}" role="alert" aria-live="polite" aria-atomic="true" class="alert bc-small alert-info">${data[0].name}</div>` : "";
			const full = this.cds.getInteractiveBreadcrumb();

			// Get last campaign stats when is not selected any campaign or risk group
			if (!this.cds.filter.campaign && !this.cds.filter.risk_group) {
				this.breadCrumb = Object.keys(this.cds.getQueryParams()).length == 0 ? last : full + last;

				if (this.cds.getTransforms().length == 0) {
					data.splice(1, data.length - 1);
				}
			}

			// Aggregate and summarize the data
			data = this.cds.applyTransform(data, this.cds.CdsXform.AggregateCampaigns);
			data = this.cds.applyTransform(data, this.cds.CdsXform.SummarizeCampaign);
			data = data[0];

			this.showChart = true;

			this.create([{ n_sent: data.total.sent, n_targets: data.total.targets }]);
			this.$forceUpdate();
		},
		create(data) {
			this.setData(data);
			this.setOptions();
		},
		setData(data) {
			let sent = 0;
			let targets = 0;
			sent = data[0].n_sent;
			targets = data[0].n_targets;

			const e = sent == 0 && targets == 0 ? 1 : 0;

			this.sent = sent;
			this.targets = targets;
			this.chartData = {
				labels: [""],
				datasets: [
					{
						data: [sent],
						backgroundColor: colors.alpha.blue,
						borderColor: colors.plain.blue,
						borderWidth: 1
					},
					{
						data: [targets - sent + e],
						backgroundColor: colors.alpha.grey,
						borderColor: colors.alpha.grey,
						borderWidth: 1
					}
				]
			};
		},
		setOptions() {
			this.options = {
				layout: {
					padding: {
						left: -20,
						top: 20
					}
				},
				scales: {
					xAxes: [
						{
							gridLines: {
								display: false
							},
							stacked: true,
							ticks: {
								callback: function () {
									return '';
								}
							}
						}
					],
					yAxes: [
						{
							stacked: true,
							gridLines: {
								display: false
							}
						}
					]
				},
				legend: {
					display: false
				},
				responsive: true,
				maintainAspectRatio: false,
				animation: {
					animateRotate: true,
					animateScale: true
				},
				tooltips: {
					enabled: false
				},
				plugins: {
					stacked100: { enable: true, replaceTooltipLabel: false },
					datalabels: {
						formatter: (_value, context) => {
							const data = context.chart.data;
							const { datasetIndex, dataIndex } = context;
							return datasetIndex == 0 && _value > 0 ? data.calculatedData[datasetIndex][dataIndex].toFixed(1) + "%" : "";
						},
						color: colors.plain.primary,
						textAlign: "center",
						font: {
							size: 12
						}
					},
					labels: {
						fontSize: 0
					}
				}
			};
		},
		removeFilter(event) {
			if (event.target.classList.contains("close")) {
				this.cds.removeFilter(event.target.getAttribute("filter"));
				this.cds.emitFilterChanges();
			}
		}
	}
};
</script>

<style lang="less">
#campaignProgress {
	min-height: 247px;
	.card-title {
		span {
			.bc-small {
				padding: 2px 10px 2px 5px !important;
			}
		}
	}
}

// Large devices (desktops, 992px and up)
@media only screen and (max-width: 991px) {
	#campaignProgress {
		h3 {
			font-size: 1.75rem !important;
		}
	}
}

@media only screen and (max-width: 1450px) {
	#campaignProgress {
		h3 {
			font-size: 1.45rem;
		}
	}
}
</style>